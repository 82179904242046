<div class="comprovante-container" *ngIf="!(token$ | async).erro; else erro">
    <div #comprovante id="comprovante">
        <div class="mat-dialog-header">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div class="logo">
                    <img src="assets/images/logos/nome_azul.png" alt="logo-flit-erro" />
                </div>
                <div fxLayout="column" class="ml-16">
                    <h1 mat-dialog-title class="margin-zero" style="margin-bottom: -13px !important; font-size: 19px">
                        {{ 'Comprovante' }}
                    </h1>
                    <p mat-dialog-title class="margin-zero secondary-text" style="font-size: 14px; font-weight: 400">
                        {{ 'via flit web' }}
                    </p>
                </div>
            </div>
        </div>
        <mat-dialog-content class="mat-typography">
            <div class="data-hora">
                <p>
                    {{ 'Realizado em' }}:
                    {{
                        dateTimeHelper
                            .retornarDateTimeEmbasadoNoTimezone(dadosPassadosDialog.marcacao.datahora.seconds, dadosPassadosDialog.marcacao.dispositivo.timezone_value)
                            .toFormat('dd/MM/yyyy')
                    }}
                    {{ 'às' }}
                    {{
                        dateTimeHelper
                            .retornarDateTimeEmbasadoNoTimezone(dadosPassadosDialog.marcacao.datahora.seconds, dadosPassadosDialog.marcacao.dispositivo.timezone_value)
                            .toFormat('HH:mm')
                    }}
                    <span style="font-size: 13px; line-height: 14px">Fuso horário: ({{ dadosPassadosDialog.marcacao.dispositivo?.timezone_value || 'NA' }})</span>
                </p>
            </div>
            <p class="margin-zero secondary-text">{{ 'Usuário' }}</p>
            <p class="margin-zero">{{ dadosPassadosDialog.usuario?.nomeCompleto }}</p>
            <p>{{ dadosPassadosDialog.usuario?.pis }}</p>

            <p class="margin-zero secondary-text">{{ 'Empresa' }}</p>
            <span *ngIf="dadosPassadosDialog.usuario?.empresa as empresa">
                <p class="margin-zero">{{ empresa?.fantasia }}</p>
                <p *ngIf="!empresa?.pessoa_fisica; else pessoafisica">{{ empresa?.cnpj }}</p>
                <ng-template #pessoafisica>
                    <p>{{ empresa?.cnpj }}</p>
                </ng-template>
            </span>

            <p class="margin-zero secondary-text">{{ 'Autenticação' }}</p>
            <p class="hash secondary-text" *ngIf="(token$ | async).token; else buscandoToken">
                {{ (token$ | async).token }}
            </p>
            <ng-template #buscandoToken>
                <div style="height: 152px !important; width: 302px !important" fxLayoutAlign="center center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </ng-template>
        </mat-dialog-content>
    </div>

    <mat-dialog-actions fxLayoutAlign="space-between center">
        <button class="botoes-acao" fxLayoutAlign="center center" mat-button mat-stroked-button color="warn" [mat-dialog-close]="true">
            <mat-icon class="mr-4">exit_to_app</mat-icon> Fechar
        </button>
        <button class="botoes-acao" fxLayoutAlign="center center" (click)="salvarComprovanteClick()" mat-button mat-stroked-button color="primary" cdkFocusInitial>
            <mat-icon class="mr-4">cloud_download</mat-icon> Salvar
        </button>
    </mat-dialog-actions>
</div>

<ng-template #erro>
    <div class="erro-container">
        <div class="mat-dialog-header">
            <div class="logo">
                <img src="assets/images/logos/nome_azul.svg" alt="logo-flit-erro-rede" />
            </div>
        </div>
        <mat-dialog-content class="mat-typography">
            <div fxLayoutAlign="center center">
                <mat-icon color="warn" class="s-60">error_outline</mat-icon>
            </div>
            <h3>Ocorreu um erro, para geração deste comprovante.</h3>
            <h4 class="secondary-text">Tente novamente mais tarde ou entre em contato com nosso suporte.</h4>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-stroked-button color="warn" [mat-dialog-close]="false">Fechar <mat-icon>exit_to_app </mat-icon></button>
        </mat-dialog-actions>
    </div>
</ng-template>
