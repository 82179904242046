import firebase from 'firebase/app';
import * as _ from 'lodash';

import { DocumentReference } from '@angular/fire/firestore';
import { FieldValue, Timestamp } from '@firebase/firestore-types';
import { UtilitariosService } from '@shared/services/utilitarios.service';
import { Departamento } from './contas/departamento.model';
import { Empresa } from './contas/empresa.model';
import { Escala } from './contas/escala.model';
import { Cargo } from './contas/cargo.model';
import { EsocialCategoriaDeTrabalhador } from './esocial-categoria-de-trabalhador.model';
import { Conta } from './conta.model';

export enum StatusUsuario {
    Afastado = 'Afastado',
    Ativo = 'Ativo',
    Demitido = 'Demitido',
    Ferias = 'Férias',
}

/**
 * Classe padrão para documentos da coleção "usuarios".
 *
 * @export
 * @class Usuario
 */
export class Usuario {
    // Campos internos universais.
    path?: string;
    ref?: DocumentReference;
    uid?: string;

    // Campos internos populados por referência.
    cargo?: Cargo;
    esocial_categoria_de_trabalhador?: EsocialCategoriaDeTrabalhador;
    conta?: Conta;
    departamento?: Departamento;
    empresa?: Empresa;
    escala?: Escala;
    perimetro?: any;

    // Campos do documento no firestore.
    aceite_termo?: Timestamp;
    admissao?: Timestamp;
    ativo?: boolean;
    avatar_thumbnail_path?: string;
    avatar_thumbnail_url?: string;
    avatarUrl?: string;
    cargo_ref?: DocumentReference;
    esocial_categoria_de_trabalhador_ref?: DocumentReference;
    celular?: string;
    conta_ref?: DocumentReference;
    cpf?: string;
    data_atualizacao_doc?: FieldValue;
    data_criacao_doc?: FieldValue;
    departamento_ref?: DocumentReference;
    desligamento?: Timestamp;
    email?: string;
    empresa_ref?: DocumentReference;
    endereco?: Endereco;
    escala_ref?: DocumentReference;
    excluido?: boolean;
    interno?: boolean;
    nascimento?: Timestamp;
    nome?: string;
    nomeCompleto?: string;
    observacoes?: string;
    parametros?: Parametros;
    perimetro_ref?: DocumentReference;
    pin?: number;
    pis?: string;
    status?: StatusUsuario;
    telefone?: string;

    constructor({
        criarSemCampos,

        aceite_termo,
        admissao,
        ativo,
        avatar_thumbnail_path,
        avatar_thumbnail_url,
        avatarUrl,
        cargo_ref,
        esocial_categoria_de_trabalhador_ref,
        celular,
        conta_ref,
        cpf,
        data_atualizacao_doc,
        data_criacao_doc,
        departamento_ref,
        desligamento,
        email,
        empresa_ref,
        endereco,
        escala_ref,
        excluido,
        interno,
        nascimento,
        nome,
        nomeCompleto,
        observacoes,
        parametros,
        perimetro_ref,
        pin,
        pis,
        status,
        telefone,
    }: {
        criarSemCampos?: boolean;

        aceite_termo?: Timestamp;
        admissao?: Timestamp;
        ativo?: boolean;
        avatar_thumbnail_path?: string;
        avatar_thumbnail_url?: string;
        avatarUrl?: string;
        cargo_ref?: DocumentReference;
        esocial_categoria_de_trabalhador_ref?: DocumentReference;
        celular?: string;
        conta_ref?: DocumentReference;
        cpf?: string;
        data_atualizacao_doc?: FieldValue;
        data_criacao_doc?: FieldValue;
        departamento_ref?: DocumentReference;
        desligamento?: Timestamp;
        email?: string;
        empresa_ref?: DocumentReference;
        endereco?: Endereco;
        escala_ref?: DocumentReference;
        excluido?: boolean;
        interno?: boolean;
        nascimento?: Timestamp;
        nome?: string;
        nomeCompleto?: string;
        observacoes?: string;
        parametros?: Parametros;
        perimetro_ref?: DocumentReference;
        pin?: number;
        pis?: string;
        status?: StatusUsuario;
        telefone?: string;
    }) {
        if (criarSemCampos) {
            if (!_.isUndefined(aceite_termo)) {
                this.aceite_termo = aceite_termo;
            }

            if (!_.isUndefined(admissao)) {
                this.admissao = admissao;
            }

            if (!_.isUndefined(ativo)) {
                this.ativo = ativo;
            }

            if (!_.isUndefined(avatar_thumbnail_path)) {
                this.avatar_thumbnail_path = avatar_thumbnail_path;
            }

            if (!_.isUndefined(avatar_thumbnail_url)) {
                this.avatar_thumbnail_url = avatar_thumbnail_url;
            }

            if (!_.isUndefined(avatarUrl)) {
                this.avatarUrl = avatarUrl;
            }

            if (!_.isUndefined(cargo_ref)) {
                this.cargo_ref = cargo_ref;
            }

            if (!_.isUndefined(esocial_categoria_de_trabalhador_ref)) {
                this.esocial_categoria_de_trabalhador_ref = esocial_categoria_de_trabalhador_ref;
            }

            if (!_.isUndefined(celular)) {
                this.celular = celular;
            }

            if (!_.isUndefined(conta_ref)) {
                this.conta_ref = conta_ref;
            }

            if (!_.isUndefined(cpf)) {
                this.cpf = cpf;
            }

            if (!_.isUndefined(data_atualizacao_doc)) {
                this.data_atualizacao_doc = data_atualizacao_doc;
            }

            if (!_.isUndefined(data_criacao_doc)) {
                this.data_criacao_doc = data_criacao_doc;
            }

            if (!_.isUndefined(departamento_ref)) {
                this.departamento_ref = departamento_ref;
            }

            if (!_.isUndefined(desligamento)) {
                this.desligamento = desligamento;
            }

            if (!_.isUndefined(email)) {
                this.email = email;
            }

            if (!_.isUndefined(empresa_ref)) {
                this.empresa_ref = empresa_ref;
            }

            if (!_.isUndefined(endereco)) {
                this.endereco = endereco;
            }

            if (!_.isUndefined(escala_ref)) {
                this.escala_ref = escala_ref;
            }

            if (!_.isUndefined(excluido)) {
                this.excluido = excluido;
            }

            if (!_.isUndefined(interno)) {
                this.interno = interno;
            }

            if (!_.isUndefined(nascimento)) {
                this.nascimento = nascimento;
            }

            if (!_.isUndefined(nome)) {
                this.nome = nome;
            }

            if (!_.isUndefined(nomeCompleto)) {
                this.nomeCompleto = nomeCompleto;
            }

            if (!_.isUndefined(observacoes)) {
                this.observacoes = observacoes;
            }

            if (!_.isUndefined(parametros)) {
                this.parametros = parametros;
            }

            if (!_.isUndefined(perimetro_ref)) {
                this.perimetro_ref = perimetro_ref;
            }

            if (!_.isUndefined(pin)) {
                this.pin = UtilitariosService.prototype.converterParaNumber(pin);
            }

            if (!_.isUndefined(pis)) {
                this.pis = pis;
            }

            if (!_.isUndefined(status)) {
                this.status = status;
            }

            if (!_.isUndefined(telefone)) {
                this.telefone = telefone;
            }
        } else {
            this.aceite_termo = aceite_termo ?? null;
            this.admissao = admissao ?? null;
            this.ativo = ativo ?? true;
            this.avatar_thumbnail_path = avatar_thumbnail_path ?? null;
            this.avatar_thumbnail_url = avatar_thumbnail_url ?? null;
            this.avatarUrl = avatarUrl ?? null;
            this.cargo_ref = cargo_ref ?? null;
            this.esocial_categoria_de_trabalhador_ref = esocial_categoria_de_trabalhador_ref ?? null;
            this.celular = celular ?? null;
            this.conta_ref = conta_ref ?? null;
            this.cpf = cpf ?? null;
            this.data_atualizacao_doc = data_atualizacao_doc ?? null;
            this.data_criacao_doc = data_criacao_doc ?? firebase.firestore.Timestamp.fromDate(new Date());
            this.departamento_ref = departamento_ref ?? null;
            this.desligamento = desligamento ?? null;
            this.email = email ?? null;
            this.empresa_ref = empresa_ref ?? null;
            this.endereco = endereco ?? new Endereco({});
            this.escala_ref = escala_ref ?? null;
            this.excluido = excluido ?? false;
            this.interno = interno ?? true;
            this.nascimento = nascimento ?? null;
            this.nome = nome ?? null;
            this.nomeCompleto = nomeCompleto ?? null;
            this.observacoes = observacoes ?? null;
            this.parametros = parametros ?? new Parametros({});
            this.perimetro_ref = perimetro_ref ?? null;
            this.pin = UtilitariosService.prototype.converterParaNumber(pin);
            this.pis = pis ?? null;
            this.status = status ?? StatusUsuario.Ativo;
            this.telefone = telefone ?? null;
        }
    }

    /**
     * Função que retorna um novo objeto somente com os dados que serão gravados no firestore e remove a tipagem.
     *
     * @returns {Object}
     * @memberof Usuario
     */
    converterParaDocumentoFirestore(): Object {
        const usuario: Partial<Usuario> = {
            aceite_termo: this.aceite_termo,
            admissao: this.admissao,
            ativo: this.ativo,
            avatar_thumbnail_path: this.avatar_thumbnail_path,
            avatar_thumbnail_url: this.avatar_thumbnail_url,
            avatarUrl: this.avatarUrl,
            cargo_ref: this.cargo_ref,
            esocial_categoria_de_trabalhador_ref: this.esocial_categoria_de_trabalhador_ref,
            celular: this.celular,
            conta_ref: this.conta_ref,
            cpf: this.cpf,
            data_atualizacao_doc: this.data_atualizacao_doc,
            data_criacao_doc: this.data_criacao_doc,
            departamento_ref: this.departamento_ref,
            desligamento: this.desligamento,
            email: this.email,
            empresa_ref: this.empresa_ref,
            endereco: this.endereco,
            escala_ref: this.escala_ref,
            excluido: this.excluido,
            interno: this.interno,
            nascimento: this.nascimento,
            nome: this.nome,
            nomeCompleto: this.nomeCompleto,
            observacoes: this.observacoes,
            parametros: this.parametros,
            perimetro_ref: this.perimetro_ref,
            pin: this.pin,
            pis: this.pis,
            status: this.status,
            telefone: this.telefone,
        };

        const usuarioFiltrado = UtilitariosService.prototype.removerCamposUndefined(usuario);

        // Forçando erro para evitar de retornar um objeto vazio, pois o .set mesmo com merge:true apaga o conteúdo se passar um objeto vazio.
        if (_.isEmpty(usuarioFiltrado)) {
            throw new Error('Função converterParaDocumentoFirestore() executada em objeto vazio!');
        }

        return usuarioFiltrado;
    }
}

/**
 * Classe interna de um campo da classe "Usuario".
 *
 * @class Endereco
 */
class Endereco {
    bairro?: string;
    cep?: string;
    cidade?: string;
    complemento?: string;
    logradouro?: string;
    numero?: string;
    uf?: string;

    constructor({
        bairro,
        cep,
        cidade,
        complemento,
        logradouro,
        numero,
        uf,
    }: {
        bairro?: string;
        cep?: string;
        cidade?: string;
        complemento?: string;
        logradouro?: string;
        numero?: string;
        uf?: string;
    }) {
        this.bairro = bairro ?? null;
        this.cep = cep ?? null;
        this.cidade = cidade ?? null;
        this.complemento = complemento ?? null;
        this.logradouro = logradouro ?? null;
        this.numero = numero ?? null;
        this.uf = uf ?? null;
    }
}

/**
 * Classe interna de um campo da classe "Usuario".
 *
 * @class Parametros
 */
class Parametros {
    acessar_manager?: boolean;
    codigo_externo_dominio?: string;
    controlar_ponto?: boolean;
    marca_ponto_apenas_online?: boolean;
    marca_ponto_flit?: boolean;
    marca_ponto_multi_empresa?: boolean;
    marca_ponto_web?: boolean;
    reconhecimento_facial_obrigatorio?: boolean;
    reconhecimento_facial?: boolean;
    limite_minutos_interjornada: number;
    horista?: boolean;

    constructor({
        acessar_manager,
        codigo_externo_dominio,
        controlar_ponto,
        marca_ponto_apenas_online,
        marca_ponto_flit,
        marca_ponto_multi_empresa,
        marca_ponto_web,
        reconhecimento_facial_obrigatorio,
        reconhecimento_facial,
        limite_minutos_interjornada,
        horista,
    }: {
        acessar_manager?: boolean;
        codigo_externo_dominio?: string;
        controlar_ponto?: boolean;
        marca_ponto_apenas_online?: boolean;
        marca_ponto_flit?: boolean;
        marca_ponto_multi_empresa?: boolean;
        marca_ponto_web?: boolean;
        reconhecimento_facial_obrigatorio?: boolean;
        reconhecimento_facial?: boolean;
        limite_minutos_interjornada?: number;
        horista?: boolean;
    }) {
        this.acessar_manager = acessar_manager ?? false;
        this.codigo_externo_dominio = codigo_externo_dominio ?? null;
        this.controlar_ponto = controlar_ponto ?? true;
        this.marca_ponto_apenas_online = marca_ponto_apenas_online ?? true;
        this.marca_ponto_flit = marca_ponto_flit ?? true;
        this.marca_ponto_multi_empresa = marca_ponto_multi_empresa ?? false;
        this.marca_ponto_web = marca_ponto_web ?? true;
        this.reconhecimento_facial = reconhecimento_facial ?? false;
        this.reconhecimento_facial_obrigatorio = reconhecimento_facial_obrigatorio ?? false;
        this.limite_minutos_interjornada = limite_minutos_interjornada;
        this.horista = horista ?? false;
    }
}
