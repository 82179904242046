import { Injectable } from '@angular/core';
import { Timestamp } from '@firebase/firestore-types';
import { EscalaCircular, JornadaEscala } from 'app/models/escala-circular.model';
import { getDay, startOfDay, differenceInDays } from 'date-fns';
import * as _ from 'lodash';

import { calcularPeriodos } from 'flit-calcular-jornada/dist/lib/helpers/utils';

import { UtilitariosService } from './utilitarios.service';

@Injectable({
    providedIn: 'root',
})
export class EscalaCircularService {
    constructor(private utilitariosService: UtilitariosService) {}

    /**
     * Associa e retorna a jornada específica de uma escala de acordo com a data no ajuste de jornada, irá simular o índice comparando a diferença
     * de dias entre a data atual da jornada e a data inicial da escala, retornando a jornada escalada equivalente.
     * Retorna uma escala convertida caso receba uma escala de modelagem antiga.
     *
     * @private
     * @param {{ escala: Escala; dataJornada: Date }} { escala, dataJornada }
     * @returns {JornadaEscala}
     * @memberof AjustarJornadaFormDialogComponent
     */
    public retornarJornadaPrevistaPelaEscala({ escala, timestampJornada }: { escala: EscalaCircular; timestampJornada: Timestamp }): JornadaEscala {
        const escalaModeloAntigo: Boolean = escala.hasOwnProperty('segunda');

        if (escalaModeloAntigo) {
            const numeroDiaDaSemana = getDay(timestampJornada.toDate());
            const nomeDiaDaSemana = (_numeroDiaDaSemana): string => {
                switch (_numeroDiaDaSemana) {
                    case 0:
                        return 'domingo';
                    case 1:
                        return 'segunda';
                    case 2:
                        return 'terca';
                    case 3:
                        return 'quarta';
                    case 4:
                        return 'quinta';
                    case 5:
                        return 'sexta';
                    case 6:
                        return 'sabado';
                }
            };
            const timezoneAtual = this.utilitariosService.converterMinutosParaTimezone(new Date().getTimezoneOffset());
            const escalaDoDia = escala[nomeDiaDaSemana(numeroDiaDaSemana)];
            const escalaDoDiaArray = [escalaDoDia?.entrada, escalaDoDia?.intervalo_inicial, escalaDoDia?.intervalo_final, escalaDoDia?.saida];
            const periodosConvertidos = _(escalaDoDiaArray)
                .compact()
                .chunk(2)
                .value()
                .map((horarios) => {
                    // Para garantir que é um periodo válido da escala antiga, com pelo menos uma entrada e uma saída, já que o chunk agrupa em pares.
                    if (horarios.length > 1) {
                        return {
                            entrada: { data_relativa: null, origem: null, hora: horarios[0], timezone_value: timezoneAtual },
                            saida: { data_relativa: null, origem: null, hora: horarios[1], timezone_value: timezoneAtual },
                            duracao: null,
                            descanso: null,
                        };
                    }
                });
            const periodosConvertidosCalculados = calcularPeriodos({ periodos: periodosConvertidos, timestampJornada: timestampJornada });

            return { periodos: periodosConvertidosCalculados };
        }

        const dataJornadaMoment = startOfDay(timestampJornada.toDate());
        const dataInicialEscalaMoment = startOfDay(escala.data_inicial.toDate());

        // Gerando um array das jornadas sequenciais para contabilizar os índices corretamente, vai criar um array repetindo os elementos de acordo com a quantidade definida no campo repetir.
        const jornadasSequenciais = escala.jornadas.reduce((arrayAcumulado, jornada) => arrayAcumulado.concat(Array(Number(jornada.repetir)).fill(jornada)), []);
        const quantidadeDias = differenceInDays(dataJornadaMoment, dataInicialEscalaMoment);
        const quantidadeJornadasSequenciais = jornadasSequenciais.length;
        const quantidadeLoops = Math.floor(quantidadeDias / quantidadeJornadasSequenciais);
        let indiceSimulado = quantidadeDias - quantidadeLoops * quantidadeJornadasSequenciais;

        // Invertendo o array e o índice quando os dias são negativos, para simular ao contrário quando a data inicial da escala é maior que a data da jornada.
        if (quantidadeDias < 0) {
            indiceSimulado = quantidadeJornadasSequenciais - 1 - indiceSimulado;
            jornadasSequenciais.reverse();
        }

        return jornadasSequenciais[indiceSimulado];
    }
}
