import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { fuseConfig } from 'app/fuse-config';
import { environment } from 'environments/environment';
import { LoggerModule } from 'ngx-logger';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { WebcamModule } from 'ngx-webcam';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/auth.service';
import { GlobalService } from './core/services/global.service';
import { MaterialModule } from './material.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';

@NgModule({
    declarations: [AppComponent, NotFoundComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireFunctionsModule,
        AngularFireAuthModule,
        Ng2ImgMaxModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AgmCoreModule.forRoot(environment.agmConf),
        HttpClientModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            autoDismiss: true,
            titleClass: 'toastr-title-gd',
            preventDuplicates: true,
        }),
        AppRoutingModule,
        AuthModule,
        MaterialModule,
        WebcamModule,
        FlexLayoutModule,
        NgxMaskModule.forRoot({ dropSpecialCharacters: false }),
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        LoggerModule.forRoot({
            serverLoggingUrl: '/api/logs',
            level: environment.logLevel,
            serverLogLevel: environment.serverLogLevel,
            httpResponseType: 'text',
            enableSourceMaps: true,
            disableConsoleLogging: environment.production,
        }),
    ],
    exports: [MaterialModule],
    providers: [
        AngularFirestore,
        {
            provide: SETTINGS,
            useValue: {},
        },
        GlobalService,
        AuthService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
