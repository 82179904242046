import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTimeHelper } from '@shared/helpers/dataTime-helper';
import { LocalStorageLog } from '@shared/helpers/local-storage-log';
import { Conta } from '@shared/models/conta.model';
import { Empresa } from '@shared/models/contas/empresa.model';
import { GlobalService } from 'app/core/services/global.service';
import { User } from 'app/models/user.model';
import { DataHoraGlobalService } from 'app/shared/services/data-hora-global.service';
import { ReconhecimentoFacialResposta, ReconhecimentoFacialService } from 'app/shared/services/reconhecimento-facial.service';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

const locale = moment.locale('pt-br');
interface MarcacaoIndividualPassedData {
    datahora: Date;
    user: Observable<User>;
    usuario: User;
    position: GeolocationPosition;
    contaDocData: Conta;
}

@Component({
    selector: 'app-marcacao',
    templateUrl: './marcacao.component.html',
    styleUrls: ['./marcacao.component.scss'],
})
export class MarcacaoComponent implements OnInit, OnDestroy {
    empresa$: Observable<Empresa>;
    private dataHoraGlobalDialogAberto: Date;

    diaMesAno;
    hora;
    timezone;
    timezoneValueName;
    user$: Observable<any>;
    dadosMarcacaoConfirmada: any;
    parametroFotografar: boolean;
    parametroMarcarWeb: boolean;
    parametroReconhecimentoFacial: boolean;

    botaoConfirmar = false;
    exibirDataEHoraContainer = false;

    counter$: Observable<number>;
    count = 60;
    activeToast: ActiveToast<any>;

    // <-- WEBCAM
    public showWebcam = false;
    public errors: WebcamInitError[] = [];
    public webcamImage: WebcamImage = null;
    private trigger: Subject<void> = new Subject<void>();
    // WEBCAM -->

    constructor(
        public dialogRef: MatDialogRef<MarcacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public passedData: MarcacaoIndividualPassedData,
        private globalService: GlobalService,
        private _rfService: ReconhecimentoFacialService,
        private _toastr: ToastrService,
        private ngxLogger: NGXLogger,
        private dataHoraGlobalService: DataHoraGlobalService,
    ) {
        setTimeout(() => {
            this.dialogRef.close();
        }, 60000);
        if (this.passedData.user) {
            this.empresa$ = this.globalService.empresa$ as any;
            this.user$ = this.passedData.user;
            this.counter$ = timer(0, 1000).pipe(
                take(this.count),
                map(() => --this.count),
            );
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Ganchos do ciclo de vida
    // -----------------------------------------------------------------------------------------------------
    async ngOnInit(): Promise<void> {
        if (this.passedData.user) {
            this.dataHoraGlobalService
                .retornarDataHoraGlobal()
                .then((resposta) => {
                    this.empresa$.pipe(take(1)).subscribe((empresa) => {
                        // console.warn({ empresa });
                        this.setarDataHora(resposta.millis, empresa.parametros?.timezone_value_padrao ? empresa.parametros?.timezone_value : undefined);
                        this.exibirDataEHoraContainer = true;
                        this.botaoConfirmar = true;
                    });
                })
                .catch((erro) => {
                    console.error(erro);
                    this.dialogRef.close();
                    LocalStorageLog.gravarArrayDeLogs({
                        buffer_length: 100,
                        data: 'Erro ao buscar data e hora global. Modal de confirmar marcação impedido de abrir, Verifique a sua conexão com a internet.',
                        key_name: 'open_modal_marcacao',
                    });
                    this._toastr.error('Ocorreu um erro ao conectar-se a um de nossos servidores. Verifique a sua conexão com a internet.', 'Erro');
                });
            // Extraindo empresa e usuário do Observable em memoria.
            const empresa = await this.empresa$.pipe(take(1)).toPromise();
            const usuario = await this.user$.pipe(take(1)).toPromise();

            this.parametroFotografar = _.get(empresa, 'parametros.permitir_fotografar_flit_web');
            this.parametroReconhecimentoFacial = _.get(usuario, 'parametros.reconhecimento_facial');
            this.parametroMarcarWeb = _.get(usuario, 'parametros.marca_ponto_web');

            this.showWebcam = this.parametroFotografar || this.parametroReconhecimentoFacial;

            this.ngxLogger.info(`\nFunção ngOnInit() executada.\nDados:`, {
                empresa,
                parametroFotografar: this.parametroFotografar,
                parametroMarcarWeb: this.parametroMarcarWeb,
                parametroReconhecimentoFacial: this.parametroReconhecimentoFacial,
                showWebcam: this.showWebcam,
                usuario,
            });
        }
    }

    ngOnDestroy(): void {
        this.trigger.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos públicos
    // -----------------------------------------------------------------------------------------------------

    setarDataHora(millis: number, tz?: string): void {
        this.dataHoraGlobalDialogAberto = new Date(millis);
        const dateTime = DateTimeHelper.retornarDateTimeEmbasadoNoTimezone(millis / 1000, tz ?? DateTime.local().toFormat('ZZ'));
        this.diaMesAno = dateTime.toFormat('dd MMMM yyyy', { locale: 'pt-BR' });
        this.hora = dateTime.toFormat('HH:mm');
        this.timezone = dateTime.toFormat('ZZ');
        if (dateTime.offset * -1 === new Date().getTimezoneOffset()) {
            this.timezoneValueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } else {
            this.timezoneValueName = null; // Não tem como pegar o timezone value name só pelo utc offset, porque existem múltiplos lugares com o mesmo utc.
        }
    }

    // RECONHECIMENTO FACIAL

    retornarUltimaLicencaContaDocSnap(contaDocData: Conta): Object {
        const arrLicencas: any[] = contaDocData.licencas || new Array();
        let ultimaLicenca: Object = {};
        if (arrLicencas.length) {
            ultimaLicenca = arrLicencas[arrLicencas.length - 1];
        }
        return ultimaLicenca;
    }

    /**
     * Função responsável por disparar e controlar o evento de clique do botão marcar do form.
     *
     * @returns {(Promise<false | ActiveToast<any>>)}
     * @memberof MarcacaoComponent
     */
    async onBtnMarcarClick(): Promise<false | ActiveToast<any>> {
        this.ngxLogger.info(`\nFunção onBtnMarcarClick executada.\nParâmetros:`, {
            _interno: {
                dataHoraGlobalDialogAberto: this.dataHoraGlobalDialogAberto,
            },
        });
        if (this.botaoConfirmar === false) {
            this.ngxLogger.error(`\nFunção onBtnMarcarClick identificou que os dados de data hora ainda não chegaram.\n`, {
                _interno: {
                    dataHoraGlobalDialogAberto: this.dataHoraGlobalDialogAberto,
                },
            });
            return false;
        }
        if (this.passedData.usuario.status !== 'Ativo') {
            return this._toastr.warning(`Ops, não é possivel marcar ponto com o status: ${(this.passedData.usuario.status || 'Indefinido').toLowerCase()}`, 'Aviso');
        }

        this.triggerSnapshot();
        if ((this.errors.length || !this.webcamImage) && this.parametroFotografar) {
            return this._toastr.warning(
                'Para realizar esta marcação é obrigatória a existência de uma câmera ativa, pois o campo "Fotografar colaborador ao marcar ponto no Flit Web." está habilitado no cadastro de empresas, verifique com seu gestor.',
                'Aviso',
            );
        }
        let face_id: ReconhecimentoFacialResposta = null;
        if (this.passedData.usuario.parametros.reconhecimento_facial && (this.retornarUltimaLicencaContaDocSnap(this.passedData.contaDocData)['reconhecimento_facial'] || false)) {
            if (this.errors.length) {
                return this._toastr.warning('O Recurso Reconhecimento facial necessita de uma câmera para a marcação.', 'Aviso');
            }
            if (!this.webcamImage) {
                return this._toastr.warning(
                    'Para marcação via reconhecimento facial é obrigatório que o campo "Fotografar colaborador ao marcar ponto no Flit Web." esteja habilitado no cadastro de empresas, verifique com seu gestor.',
                    'Aviso',
                );
            }
            this.botaoConfirmar = false;
            face_id = await this._rfService.verificarFace(this.passedData.contaDocData.uid, this.webcamImage.imageAsBase64);
            this.botaoConfirmar = true;
            if (!this._rfService.confirmarSeEaFaceDoUsuarioLogado(face_id, this.passedData.usuario)) {
                this._toastr.warning(`${this.passedData.usuario.nome} não te reconheci, pode tentar novamente?`, 'Aviso!');
                return false;
            }
        }
        this.dialogRef.close(this.retornar(face_id));
    }

    public triggerSnapshot(): void {
        this.trigger.next();
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
        this.showWebcam = false;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;
    }

    retornar(faceId: ReconhecimentoFacialResposta): any {
        const returnData: any = this.passedData;
        returnData.confirmClick = new Date();
        returnData.timezone = this.timezone;
        returnData.timezoneValueName = this.timezoneValueName;
        returnData.webcamImage = this.webcamImage;
        returnData.parametroFotografar = this.parametroFotografar;
        returnData.position = returnData.position;
        returnData.face_id = faceId;
        returnData.dataHoraGlobalDialogAberto = this.dataHoraGlobalDialogAberto;
        return returnData;
    }
}
