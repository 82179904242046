import { EntradaESaida } from '@shared/models/entrada-saida.model';

export class EscalaDiaApurada {
    escalaDoDiaAjustada: EntradaESaida<Date>[];
    intervalosPrevistos: number[];
    tempoEscalaDoDiaEmMinutos: number; // Começa com menos 2 para saber que ainda não foi usado.
    tempoIntervaloEscalaDoDiaEmMinutos: number; // Começa com menos 2 para saber que ainda não foi usado.

    constructor({ escalaDoDiaAjustada, intervalosPrevistos, tempoEscalaDoDiaEmMinutos, tempoIntervaloEscalaDoDiaEmMinutos }: Partial<EscalaDiaApurada> = {}) {
        this.escalaDoDiaAjustada = escalaDoDiaAjustada ?? [];
        this.intervalosPrevistos = intervalosPrevistos ?? [];
        this.tempoEscalaDoDiaEmMinutos = tempoEscalaDoDiaEmMinutos ?? -2;
        this.tempoIntervaloEscalaDoDiaEmMinutos = tempoIntervaloEscalaDoDiaEmMinutos ?? -2;

    }
}
