import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CollectionReference } from '@firebase/firestore-types';
import { MarcacaoModel } from '@models/marcacao.model';
import { leftJoinDocument } from '@shared/helpers/collectionJoin';
import { EntradaESaida } from '@shared/models/entrada-saida.model';
import { Usuario } from '@shared/models/usuario.model';
import { EscalaCircularService } from '@shared/services/escala-circular.service';
import { tipoDate, tipoDocumentReference, tipoTimestampFirestore } from '@shared/services/utilitarios.service';
import { GlobalService } from 'app/core/services/global.service';
import { addDays, differenceInDays, differenceInMinutes, endOfDay, parse, setSeconds, startOfDay, addMinutes, subMinutes, subDays } from 'date-fns';
import firebase from 'firebase/app';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, noop, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, take, takeUntil } from 'rxjs/operators';

import { EscalaDiaApurada } from './models/escala-dia.model';
import { JornadaAnteriorRefInfo, TrabalhoDoDiaApurado } from './models/trabalho-dia.model';

/**
 * Class destinada a tratar o usuário logado.
 * Esse serviço é exclusivo da parte do sistema referente ao usuário.
 * O acesso por empresa deve ter seu próprio serviço isolado.
 *
 * TODO: Usar esse serviço como base de tudo em substituir o main.service.ts que esta com a estrutura antiga.
 *
 * @export
 * @class UsuarioLogadoService
 * @implements {Resolve<boolean>}
 */
@Injectable({
    providedIn: 'root',
})
export class UsuarioLogadoService implements Resolve<boolean> {
    ipExterno$: BehaviorSubject<string> = new BehaviorSubject('-2');
    usuarioLogado$: BehaviorSubject<Usuario> = new BehaviorSubject(new Usuario({}));
    historicoFiltroCache$: BehaviorSubject<MarcacaoModel[]> = new BehaviorSubject([]);
    marcacoesDoDia$: BehaviorSubject<MarcacaoModel[]> = new BehaviorSubject([-2] as any);
    fusoLocal$ = new BehaviorSubject<boolean>(false);
    // * <-- Escala
    escalaApurada$: BehaviorSubject<EscalaDiaApurada> = new BehaviorSubject(new EscalaDiaApurada());
    horista$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    // * Escala -->
    // * <-- Trabalho do dia apurado
    trabalhoDoDiaApurado$: BehaviorSubject<TrabalhoDoDiaApurado> = new BehaviorSubject(new TrabalhoDoDiaApurado());
    // * Trabalho do dia apurado -->
    private serviceSubs$: BehaviorSubject<{ fn_ref: string; sub: Subscription }[]> = new BehaviorSubject([]);

    /**
     * Cria uma instância do UsuarioLogadoService.
     * @param {GlobalService} globalService
     * @param {NGXLogger} ngxLogger
     * @param {AngularFirestore} angularFirestore
     * @param {AngularFireAuth} angularFireAuth
     * @memberof UsuarioLogadoService
     */
    constructor(
        private globalService: GlobalService,
        private ngxLogger: NGXLogger,
        private angularFirestore: AngularFirestore,
        private angularFireAuth: AngularFireAuth,
        private escalaCircularService: EscalaCircularService,
        private angularFireFunctions: AngularFireFunctions,
    ) {
        this.ngxLogger.info(`\nFunção construtor de UsuarioLogadoService executado.\nParâmetros:`, {});
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos públicos
    // -----------------------------------------------------------------------------------------------------
    /**
     * Método para resolução de rotas.
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {*}
     * @memberof UsuarioLogadoService
     */
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const _fnId = Math.random().toString(36).slice(-8);
        const unsubscribe = new Subject(); // Helper para se desinscrever depois que o valor chegou.
        this.ngxLogger.info(`\nFunção UsuarioLogadoService.resolve executada.\nParâmetros:`, { route, state, _interno: { unsubscribe, _fnId } });

        /**
         * Chamando @angularFireAuth para garantir que o provedor de autenticação do usuario
         * já foi iniciado e resolvido.
         */
        this.angularFireAuth.authState.pipe(take(1)).subscribe((firebaseUser) => {
            this.ngxLogger.info(`\nangularFireAuth.authState valor.\nParâmetros:`, { firebaseUser });
            if (firebaseUser) {
                this.buscarUsuarioLogado();
            }
        });

        // Verificando se o valor já foi alimentado para encadear a chamada de observadores que dependem dos dados do usuário logado.
        combineLatest([this.usuarioLogado$, this.escalaApurada$])
            .pipe(takeUntil(unsubscribe))
            .subscribe(([usuario, escalaApurada]) => {
                this.ngxLogger.debug(`\n UsuarioLogadoService => resolve => combineLatest([this.usuarioLogado$, this.escalaApurada$]) emitiu valor.\nDados:`, {
                    usuario,
                    escalaApurada,
                    _internal: { _fnId },
                });
                if (
                    usuario.conta_ref?.path &&
                    this.serviceSubs$.getValue().findIndex((s) => (s.fn_ref = 'observarTodasMarcacoesDoDia')) !== -1 &&
                    escalaApurada.tempoEscalaDoDiaEmMinutos !== -2
                ) {
                    // Caso tenha o dados eu cancelo a inscrição desse observador para não duplicar a inscrição.
                    const dataIni = _.first(escalaApurada.escalaDoDiaAjustada)?.entrada ?? DateTime.local().startOf('day').toJSDate();
                    const dataFim = _.last(escalaApurada.escalaDoDiaAjustada)?.saida ?? DateTime.local().endOf('day').toJSDate();
                    unsubscribe.next();
                    unsubscribe.complete();
                    if (usuario.empresa.parametros.restricao_ip_marcar_web) {
                        this.buscarIp(); // Só busca o ip caso tenha necessidade.
                    }
                    if (dataIni && dataFim) {
                        const dataIniStart = startOfDay(subDays(new Date(), 1));
                        const dataFimEnd = endOfDay(addDays(new Date(), 1));
                        // TODO: Aqui eu vou usar outra estrategia, em vez de se basear pela escala, eu vou me basear pelo intervalo das marcações com base na intra jornada.
                        const limite_minutos_interjornada = usuario.parametros.limite_minutos_interjornada || usuario.empresa.parametros.limite_minutos_interjornada || 600;
                        this.observarTodasMarcacoesDoDia(dataIniStart, dataFimEnd, limite_minutos_interjornada);
                    }
                }
            });
    }

    /**
     * Buscar histórico de marcações com base em um filtro de data.
     *
     * @param {Date} dataIni
     * @param {Date} dataFim
     * @memberof UsuarioLogadoService
     */
    public buscarHistoricoDeMarcacoes(dataIni: Date, dataFim: Date): void {
        // Limpando inscrição antiga no caso de uma nova consulta.
        this.limparInscricaoEspecifica('buscarHistoricoDeMarcacoes');

        // Desestruturando valores.
        const { conta_ref, ref } = this.usuarioLogado$.getValue();

        this.ngxLogger.info(`\nFunção buscarHistoricoDeMarcacoes executada.\nParâmetros:`, {
            dataIni,
            dataFim,
            _interno: {
                conta_ref,
                ref,
            },
        });

        // Confirmando se tenho tudo do banco, para prosseguir.
        if (tipoDocumentReference(conta_ref) && tipoDocumentReference(ref)) {
            const sub: Subscription = this.angularFirestore
                .collection<MarcacaoModel>(`${conta_ref.path}/marcacoes`, (colRef: CollectionReference) =>
                    colRef.where('datahora', '>', dataIni).where('datahora', '<', dataFim).where('usuario_ref', '==', ref),
                )
                .snapshotChanges()
                .pipe(
                    map((marcacoes) =>
                        marcacoes.map((marcacao) => {
                            return {
                                uid: marcacao.payload.doc.id,
                                ref: marcacao.payload.doc.ref,
                                path: marcacao.payload.doc.ref.path,
                                ...marcacao.payload.doc.data(),
                            } as MarcacaoModel;
                        }),
                    ),
                )
                .subscribe(
                    (listaMarcacoes) => {
                        this.historicoFiltroCache$.next(listaMarcacoes);
                    },
                    (erro) => {
                        this.ngxLogger.error(`\nErro no subscribe da função buscarHistoricoDeMarcacoes.\n`, erro);
                    },
                );
            this.serviceSubs$.next(this.serviceSubs$.getValue().concat({ fn_ref: 'buscarHistoricoDeMarcacoes', sub }));
        } else {
            this.ngxLogger.error(`\nErro na função buscarHistoricoDeMarcacoes.\n`, { message: `Usuario não carregou a sua ref ou conta_ref, para buscar.` });
        }
    }

    /**
     * Chame essa função para limpar o fluxo de inscrições do serviço.
     *
     * @memberof UsuarioLogadoService
     */
    limparInscricoesDoServico(): void {
        this.ngxLogger.info(`\nFunção limparInscricoesDoServico executada.\nParâmetros:`, { _interno: { serviceSubs: this.serviceSubs$ } });
        for (const iterator of this.serviceSubs$.getValue()) {
            iterator.sub.unsubscribe();
        }
        // Limpando array depois de se desinscrever.
        this.serviceSubs$.next([]);
        this.globalService.removerInscricoesDoGlobalService();
        // Limpando dados em cache.
        this.usuarioLogado$.next(new Usuario({}));
        this.historicoFiltroCache$.next([]);
        this.marcacoesDoDia$.next([]);
        this.escalaApurada$.next(new EscalaDiaApurada());
        this.trabalhoDoDiaApurado$.next(new TrabalhoDoDiaApurado());
    }
    /**
     * Ajustar marcações para conceito de entrada e saida.
     *
     * @public
     * @template T
     * @param {T[]} marcacoes
     * @returns {EntradaESaida<T>[]}
     * @memberof UsuarioLogadoService
     */
    public ajustarMarcacoesParaConceitoEntradaESaida<T>(marcacoes: T[]): EntradaESaida<T>[] {
        const arr: EntradaESaida<T>[] = new Array();
        for (let i = 0; i < marcacoes.length; i += 2) {
            arr.push({ entrada: marcacoes[i], saida: marcacoes[i + 1] ?? null });
        }
        return arr;
    }

    /**
     * Ajustando escala para conceito de entrada e saida.
     * *OK: Depois as escalas no manager vão mudar a estrutura para receber N = ? horários.
     * *OK: Desta forma aqui vai ter que ganhar uma pequena refatoração, para trabalhar com
     * *OK: a estrutura de lá, que provavelmente será um array de horários com entrada e saida.
     *
     * @public
     * @template T
     * @param {Usuario} usuario
     * @returns {EntradaESaida<T>[]}
     * @memberof UsuarioLogadoService
     */
    public ajustarEscalaParaConceitoEntradaESaida(usuario: Usuario): EntradaESaida<Date>[] {
        this.ngxLogger.info(`\nFunção ajustarEscalaParaConceitoEntradaESaida executada.\nParâmetros:`, { usuario });
        try {
            const jornadaPrevistaPelaEscala = this.escalaCircularService.retornarJornadaPrevistaPelaEscala({
                escala: usuario.escala as any,
                timestampJornada: firebase.firestore.Timestamp.fromDate(new Date()),
            });

            const entradaESaida = jornadaPrevistaPelaEscala.periodos.reduce<EntradaESaida<Date>[]>((acumulador, elementoAtual, indexAtual) => {
                const entrada = parse(elementoAtual.entrada.hora, 'HH:mm', new Date());
                const saida = parse(elementoAtual.saida.hora, 'HH:mm', new Date());
                const entradaReal = !indexAtual ? entrada : /*IF*/ acumulador[indexAtual - 1].saida.getTime() > entrada.getTime() ? addDays(entrada, 1) : entrada; /*IF*/
                const saidaReal = !indexAtual ? saida : /*IF*/ entradaReal.getTime() > saida.getTime() ? addDays(saida, 1) : saida; /*IF*/
                return acumulador.concat({
                    entrada: entradaReal,
                    saida: saidaReal,
                });
            }, []);
            this.ngxLogger.info(`\nFunção ajustarEscalaParaConceitoEntradaESaida apurou os Dados. \nParâmetros:`, { jornadaPrevistaPelaEscala, entradaESaida });
            return entradaESaida;
        } catch (error) {
            console.warn('Sem escala ou fora da estrutura esperada.');
            console.log(error);
            return [];
        }
    }

    /**
     * Calculo total de horas trabalhadas com base nas marcações feitas durante o dia,
     * independente de quantas marcações forem feitas.
     *
     * @public
     * @param {EntradaESaida<MarcacaoModel>[]} marcacoes
     * @returns {number}
     * @memberof UsuarioLogadoService
     */
    public calcularHorasTrabalhadasEmMinutos(marcacoes: EntradaESaida<MarcacaoModel>[], tipo: 'trabalho' | 'intervalo' | 'intervaloArray' = 'trabalho'): number {
        let tempoApurado = 0;
        try {
            if (tipo === 'trabalho') {
                for (const iterator of marcacoes) {
                    // Verificando se esta na estrutura que eu espero receber.
                    if (tipoTimestampFirestore(iterator.entrada?.datahora) && (tipoTimestampFirestore(iterator.saida?.datahora) || iterator.saida === null)) {
                        // Verificando se tem saida ou não, caso não tenha, não tem como eu saber quanto tempo ele trabalhou depois que entrou.
                        switch (iterator.saida) {
                            case null:
                                continue;
                            default:
                                const entrada: Date = setSeconds(iterator.entrada.datahora.toDate(), 0);
                                const saida: Date = setSeconds(iterator.saida.datahora.toDate(), 59);
                                const diferencaEmMinutos: number = differenceInMinutes(saida, entrada);
                                tempoApurado += diferencaEmMinutos;
                                break;
                        }
                    } else {
                        throw new Error('Alguma marcação esta fora da estrutura esperada.');
                    }
                }
            } else if (tipo === 'intervalo') {
                for (let i = 0; i < marcacoes.length; i++) {
                    if (tipoTimestampFirestore(marcacoes[i + 1]?.entrada?.datahora) && tipoTimestampFirestore(marcacoes[i]?.saida?.datahora)) {
                        tempoApurado += differenceInMinutes(setSeconds(marcacoes[i + 1].entrada.datahora.toDate(), 59), setSeconds(marcacoes[i].saida.datahora.toDate(), 0));
                    } else {
                        // Aqui não emitimos erro pois pode ter tido intervalo ou não.
                        noop();
                    }
                }
            }
        } catch (erro) {
            console.error(erro);
            return -1;
        }
        return tempoApurado;
    }

    /**
     * Calculo total de horas trabalhadas com base na @param escalaAjustada$
     * @public
     * @param {EntradaESaida<MarcacaoModel>[]} escala
     * @returns {number}
     * @memberof UsuarioLogadoService
     */
    public calcularTempoEscalaEmMinutos(escala: EntradaESaida<Date>[], tipo: 'trabalho' | 'intervalo' = 'trabalho'): number {
        let tempoApurado = 0;
        try {
            if (tipo === 'trabalho') {
                for (const iterator of escala) {
                    // Verificando se esta na estrutura que eu espero receber.
                    if (tipoDate(iterator.entrada) && tipoDate(iterator.saida)) {
                        // Verificando se tem saida ou não, caso não tenha, não tem como eu saber quanto tempo ele trabalhou depois que entrou.
                        switch (iterator.saida) {
                            case null:
                                continue;
                            default:
                                const entrada: Date = setSeconds(iterator.entrada, 0);
                                const saida: Date = setSeconds(iterator.saida, 59);
                                const diferencaEmMinutos: number = differenceInMinutes(saida, entrada);
                                tempoApurado += diferencaEmMinutos;
                                break;
                        }
                    } else {
                        throw new Error('Alguma marcação esta fora da estrutura esperada.');
                    }
                }
            } else if (tipo === 'intervalo') {
                for (let i = 0; i < escala.length; i++) {
                    if (tipoDate(escala[i + 1]?.entrada) && tipoDate(escala[i]?.saida)) {
                        tempoApurado += differenceInMinutes(setSeconds(escala[i + 1].entrada, 59), setSeconds(escala[i].saida, 0));
                    } else {
                        // Aqui não emitimos erro pois pode ter tido intervalo ou não.
                        continue;
                    }
                }
            } else {
                throw new Error('Tipo não foi informado pode ser: trabalho | intervalo');
            }
        } catch (erro) {
            console.error(erro);
            return -1;
        }
        return tempoApurado;
    }

    /**
     * Calcular intervalos previstos e retornar os mesmos sequencialmente em um array.
     *
     * @public
     * @param {EntradaESaida<Date>[]} escala
     * @returns {number[]}
     * @memberof UsuarioLogadoService
     */
    public calcularIntervalosPrevistos(escala: EntradaESaida<Date>[]): number[] {
        const intervalosPrev = new Array<number>();
        try {
            for (let i = 0; i < escala.length; i++) {
                if (tipoDate(escala[i + 1]?.entrada) && tipoDate(escala[i]?.saida)) {
                    intervalosPrev.push(differenceInMinutes(setSeconds(escala[i + 1].entrada, 59), setSeconds(escala[i].saida, 0)));
                } else {
                    // Aqui não emitimos erro pois pode ter tido intervalo ou não.
                    continue;
                }
            }
        } catch (erro) {
            console.error(erro);
            return [-1];
        }
        return intervalosPrev;
    }

    /**
     * Calcular intervalos feitos e retornar os mesmos sequencialmente em um array.
     *
     * @public
     * @param {EntradaESaida<Date>[]} marcacoes
     * @returns {number[]}
     * @memberof UsuarioLogadoService
     */
    public calcularArrayIntervalosFeitos(marcacoes: EntradaESaida<MarcacaoModel>[]): number[] {
        const intervalosFeitos = new Array<number>();
        try {
            for (let i = 0; i < marcacoes.length; i++) {
                if (tipoTimestampFirestore(marcacoes[i + 1]?.entrada?.datahora) && tipoTimestampFirestore(marcacoes[i]?.saida?.datahora)) {
                    intervalosFeitos.push(differenceInMinutes(setSeconds(marcacoes[i + 1].entrada.datahora.toDate(), 59), setSeconds(marcacoes[i].saida.datahora.toDate(), 0)));
                } else {
                    // Aqui não emitimos erro pois pode ter tido intervalo ou não.
                    continue;
                }
            }
        } catch (erro) {
            console.error(erro);
            return [-1];
        }
        return intervalosFeitos;
    }

    /**
     * Função para compensar intervalos da escala com os intervalos feitos
     * e calcular um horario de saida mais preciso.
     * Este conversor foi pensado para trabalhar em escalas com mais de 4 marcações,
     * assim como todo serviço.
     * Exemplo:
     * ESCALA NORMAL 8 HORAS
     * 07:00 08:00 09:00 11:00 12:00 17:00
     *        @60min(1)    @60min(2)
     *
     * MARCACOES FEITAS TAMBEM 8 HORAS DE TRABALHO
     * 07:00 08:00 08:30 11:00 12:00 16:30
     *        @30min(1)   @60min(2)
     *
     *  A diferença é que ele identifica que fez menos
     * almoço do que previsto e compensa, para estimar
     * melhor a hora de saida sem o colaborador, se fizer
     * mais almoço não é nescessario compensar porque
     * onde é feita estimativa de saida, vai ver a diferença
     * e vai jogar a mais.
     *
     * @public
     * @param {{
     *         intervalosPrevistos: number[];
     *         intervalosFeitos: number[];
     *     }} {
     *         intervalosPrevistos,
     *         intervalosFeitos,
     *     }
     * @returns {{
     *         intervalosCompensados: number[];
     *         intervalosCompensadosMinutos: number;
     *     }}
     * @memberof UsuarioLogadoService
     */
    public compensarIntervalosEscalaComFeitos({
        intervalosPrevistos,
        intervalosFeitos,
    }: {
        intervalosPrevistos: number[];
        intervalosFeitos: number[];
    }): {
        intervalosCompensados: number[];
        intervalosCompensadosMinutos: number;
    } {
        const intervalosCompensados: number[] = [];
        for (const { index, intervaloPrevisto } of intervalosPrevistos.map((intervalo_map, index_map) => ({ index: index_map, intervaloPrevisto: intervalo_map }))) {
            const intervaloFeito = intervalosFeitos[index];
            if (intervaloFeito) {
                intervaloFeito >= intervaloPrevisto ? intervalosCompensados.push(intervaloPrevisto) : intervalosCompensados.push(intervaloFeito);
            } else {
                intervalosCompensados.push(intervaloPrevisto);
            }
        }
        return { intervalosCompensados, intervalosCompensadosMinutos: intervalosCompensados.reduce((a, b) => a + b, 0) };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos privados
    // -----------------------------------------------------------------------------------------------------
    /**
     * Função que busca o usuário logado e faz join em todas as informações relevantes referente a ele.
     * Obs: Essa função também cuida de apurar os valores
     * para: @param {BehaviorSubject<EscalaDiaApurada>} escalaApurada$
     *
     * @private
     * @memberof UsuarioLogadoService
     */
    private buscarUsuarioLogado(): void {
        const _fnId = Math.random().toString(36).slice(-8);
        this.ngxLogger.info(`\nFunção buscarUsuarioLogado executada.\nParâmetros:`, { _internal: { _fnId } });
        const sub = this.angularFirestore
            .doc<Partial<Usuario>>(`usuarios/${this.globalService.uidUsuarioLogado}`)
            .snapshotChanges()
            .pipe(
                map((docSnap) => {
                    return [{ uid: docSnap.payload.id, ref: docSnap.payload.ref, path: docSnap.payload.ref.path, ...docSnap.payload.data() }] as Usuario[];
                }),
                leftJoinDocument(this.angularFirestore, this.ngxLogger, 'conta_ref', 'conta'),
                leftJoinDocument(this.angularFirestore, this.ngxLogger, 'empresa_ref', 'empresa'),
                leftJoinDocument(this.angularFirestore, this.ngxLogger, 'escala_ref', 'escala'),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
            .subscribe(
                (usuario: Usuario[]) => {
                    this.ngxLogger.info(`\nSub da função buscarUsuarioLogado emitiu valor.\nDados:`, {
                        usuario,
                        _internal: {
                            _fnId,
                        },
                    });
                    // Setando Fazendo cache do usuario.
                    this.usuarioLogado$.next(usuario[0]);
                    // Gerenciando dados de horista.
                    this.horista$.next(usuario[0]?.parametros?.horista ?? false);

                    // Gerenciando dados de escala de acordo com o usuario.
                    // Formatando escala para o padrão de entrada e saida.
                    const escalaDoDiaAjustada = this.ajustarEscalaParaConceitoEntradaESaida(usuario[0]);
                    // Apurando todos os dados referentes a escala.
                    this.escalaApurada$.next({
                        escalaDoDiaAjustada,
                        intervalosPrevistos: this.calcularIntervalosPrevistos(escalaDoDiaAjustada),
                        tempoEscalaDoDiaEmMinutos: this.calcularTempoEscalaEmMinutos(escalaDoDiaAjustada),
                        tempoIntervaloEscalaDoDiaEmMinutos: this.calcularTempoEscalaEmMinutos(escalaDoDiaAjustada, 'intervalo'),
                    });
                },
                (erro) => {
                    this.ngxLogger.error(`\nErro na promise da função buscarUsuarioLogado.\n`, erro);
                },
            );

        this.serviceSubs$.next(this.serviceSubs$.getValue().concat({ fn_ref: 'buscarUsuarioLogado', sub }));
    }

    /**
     * Função de uso interno com intuito de se desinscrever e limpar uma parte array de inscrições.
     *
     * @private
     * @param {string} ref
     * @memberof UsuarioLogadoService
     */
    private limparInscricaoEspecifica(ref: string): void {
        this.ngxLogger.info(`\nFunção limparInscricaoEspecifica executada.\nParâmetros:`, { ref, _interno: { serviceSubs: this.serviceSubs$ } });
        for (const iterator of this.serviceSubs$.getValue()) {
            if (iterator.fn_ref === ref) {
                iterator.sub.unsubscribe();
            }
        }
        // Limpando array depois de se desinscrever do solicitado.
        this.serviceSubs$.next(this.serviceSubs$.getValue().filter((ssub) => ssub.fn_ref !== ref));
    }

    /**
     * Observar todas as marcações do dia referente ao usuario logado.
     * Obs: Essa função também cuida de apurar os valores
     * para: @param {BehaviorSubject<TrabalhoDoDiaApurado>} trabalhoDoDiaApurado$
     *
     * @private
     * @memberof UsuarioLogadoService
     */
    private observarTodasMarcacoesDoDia(
        dataIni = new Date(new Date().setHours(0, 0, 0, 0)),
        dataFim = new Date(new Date().setHours(23, 59, 59, 59)),
        limite_minutos_interjornada: number = 600,
    ): void {
        const _fnId = Math.random().toString(36).slice(-8);
        this.ngxLogger.info(`\nFunção observarTodasMarcacoesDoDia executada.\nParâmetros:`, { _fnId, dataIni, dataFim, limite_minutos_interjornada });

        const sub = this.angularFirestore
            .collection<MarcacaoModel>(`${this.usuarioLogado$.getValue().conta_ref.path}/marcacoes`, (colRef: CollectionReference) =>
                colRef.where('datahora', '>', dataIni).where('datahora', '<', dataFim).where('usuario_ref', '==', this.usuarioLogado$.getValue().ref),
            )
            .snapshotChanges()
            .pipe(
                map((marcacoes) =>
                    marcacoes.map((marcacao) => {
                        return {
                            uid: marcacao.payload.doc.id,
                            ref: marcacao.payload.doc.ref,
                            path: marcacao.payload.doc.ref.path,
                            ...marcacao.payload.doc.data(),
                        } as MarcacaoModel;
                    }),
                ),
                distinctUntilChanged((x, y) =>
                    _.isEqual(
                        x.map(({ uid, datahora }) => {
                            return { uid, datahora } as Partial<MarcacaoModel>;
                        }),
                        y.map(({ uid, datahora }) => {
                            return { uid, datahora } as Partial<MarcacaoModel>;
                        }),
                    ),
                ),
            )
            .subscribe(
                (marcacoes) => {
                    this.ngxLogger.debug(`\nInscrição de observarTodasMarcacoesDoDia emitiu valor.\nParâmetros:`, { marcacoes, _interno: { _fnId } });
                    // #region Propriedades para apurar no processamento
                    let marcacoesTratadas: MarcacaoModel[] = [];
                    const jornadaAnteriorRefInfo: JornadaAnteriorRefInfo = {
                        data_fim: undefined,
                        diff: undefined,
                        index_ref: undefined,
                        ultima_marcacao: undefined,
                    };
                    // #endregion

                    if (marcacoes.length) {
                        const dateNow = new Date();
                        const dataUltimaMarcacao = _.last(marcacoes)?.datahora?.toDate();
                        const diffUltimaMarcacao = differenceInMinutes(dateNow, dataUltimaMarcacao);
                        if (dataUltimaMarcacao && diffUltimaMarcacao >= limite_minutos_interjornada) {
                            marcacoesTratadas = [];
                        } else {
                            const marcacoesReduce = marcacoes.reduce<MarcacaoModel[]>((acumulador, elementoAtual, indexAtual, arrayOriginal) => {
                                if (arrayOriginal.length === 1) {
                                    return acumulador.concat(elementoAtual);
                                } else {
                                    if (indexAtual) {
                                        const diff = differenceInMinutes(elementoAtual.datahora.toDate(), arrayOriginal[indexAtual - 1].datahora.toDate());
                                        if (diff <= limite_minutos_interjornada) {
                                            return acumulador.concat(elementoAtual);
                                        } else {
                                            jornadaAnteriorRefInfo.data_fim = addMinutes(arrayOriginal[indexAtual - 1].datahora.toDate(), limite_minutos_interjornada);
                                            jornadaAnteriorRefInfo.diff = diff;
                                            jornadaAnteriorRefInfo.index_ref = indexAtual - 1;
                                            jornadaAnteriorRefInfo.ultima_marcacao = arrayOriginal[indexAtual - 1];
                                            return [elementoAtual];
                                        }
                                    } else {
                                        return [elementoAtual];
                                    }
                                }
                            }, []);
                            marcacoesTratadas = marcacoesReduce;
                        }
                    }
                    this.marcacoesDoDia$.next(marcacoesTratadas);
                    // Alimentando array de marcações de forma tratada.
                    const marcacoesDoDiaAjustadas = this.ajustarMarcacoesParaConceitoEntradaESaida(marcacoesTratadas);
                    this.trabalhoDoDiaApurado$.next({
                        jornadaAnteriorRefInfo,
                        marcacoesDoDiaAjustadas,
                        tempoIntervaloDoDiaArray: this.calcularArrayIntervalosFeitos(marcacoesDoDiaAjustadas),
                        tempoIntervaloDoDiaEmMinutos: this.calcularHorasTrabalhadasEmMinutos(marcacoesDoDiaAjustadas, 'intervalo'),
                        tempoTrabalhadoNoDiaEmMinutos: this.calcularHorasTrabalhadasEmMinutos(marcacoesDoDiaAjustadas),
                    });
                },
                (erro) => {
                    this.ngxLogger.error(`\nErro no subscribe da função observarTodasMarcacoesDoDia.\n`, erro);
                },
            );

        this.serviceSubs$.next(this.serviceSubs$.getValue().concat({ fn_ref: 'observarTodasMarcacoesDoDia', sub }));
    }

    private buscarIp(): void {
        const data$ = this.angularFireFunctions.httpsCallable('oncall_verificar_ip')({});
        data$.pipe(take(1)).subscribe(
            (res) => {
                this.ipExterno$.next(res.ip);
            },
            (err) => {
                this.ipExterno$.next('');
            },
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Getters
    // -----------------------------------------------------------------------------------------------------
    get inscricoesDoServico$(): BehaviorSubject<
        {
            fn_ref: string;
            sub: Subscription;
        }[]
    > {
        return this.serviceSubs$;
    }
}
