import firebase from 'firebase/app';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, defer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

export const leftJoin = (afs: AngularFirestore, ngxLogger: NGXLogger, field, collection, limit = 100) => {
    return (source) =>
        defer(() => {
            // Operator state
            let collectionData;

            // Track total num of joined doc reads
            let totalJoins = 0;

            return source.pipe(
                switchMap((data) => {
                    // Clear mapping on each emitted val ;

                    // Save the parent data state
                    collectionData = data as any[];

                    const reads$ = [];
                    for (const doc of collectionData) {
                        // Push doc read to Array

                        if (doc[field]) {
                            // Perform query on join key, with optional limit
                            const q = (ref) => ref.where(field, '==', doc[field]).limit(limit);

                            reads$.push(afs.collection(collection, q).valueChanges());
                        } else {
                            reads$.push(of([]));
                        }
                    }

                    return combineLatest(reads$);
                }),
                map((joins) => {
                    return collectionData.map((v, i) => {
                        totalJoins += joins[i].length;
                        return { ...v, [collection]: joins[i] || null };
                    });
                }),
                tap((final) => {
                    ngxLogger.trace(`\nQueries executadas: ${(final as any).length}\nJoins realizados: ${totalJoins}\nCampo: ${field}`);
                    totalJoins = 0;
                }),
            );
        });
};

export const leftJoinDocument = (afs: AngularFirestore, ngxLogger: NGXLogger, reference, field: string) => {
    return (source) =>
        defer(() => {
            // Operator state
            let collectionData;
            const cache = new Map();

            return source.pipe(
                switchMap((data) => {
                    // Clear mapping on each emitted val ;
                    cache.clear();

                    // Save the parent data state
                    collectionData = data as any[];

                    const reads$ = [];
                    let i = 0;

                    for (const doc of collectionData) {
                        // Skip if doc field does not exist or is already in cache
                        if (!doc[reference] || doc[reference] === undefined || !doc[reference].path || cache.get(doc[reference].path)) {
                            continue;
                        }

                        // Push doc read to Array
                        reads$.push(
                            afs
                                .doc(doc[reference].path)
                                .snapshotChanges()
                                .pipe(
                                    map((dadosDoc: any) => {
                                        const docData: Object = dadosDoc.payload.data();
                                        const uid = dadosDoc.payload.id;
                                        const docRef = dadosDoc.payload.ref;
                                        return { uid: uid, path: docRef.path, ref: docRef, ...docData };
                                    }),
                                ),
                        );
                        cache.set(doc[reference].path, i);
                        i++;
                    }

                    return reads$.length ? combineLatest(reads$) : of([]);
                }),
                map((joins) => {
                    return collectionData.map((v, i) => {
                        if (!v[reference] || v[reference] === undefined || !v[reference].path) {
                            return { ...v };
                        }

                        const joinIdx = cache.get(v[reference].path);
                        return { ...v, [field]: joins[joinIdx] || null };
                    });
                }),
                tap((final) => ngxLogger.trace(`\nQueries executadas: ${(final as any).length}\nJoins realizados: ${cache.size}\nCampo: ${field}`)),
            );
        });
};

// export const getProp = (obj, propName) => { // For later use to get nested properties passed on methods.
//     const parts = propName.split('.');

//     for (let i = 0; i < parts.length; i++) {
//         obj = obj[parts[i]];
//     }
//     return obj;
// };

export const storageUrlPathJoin = (afs: AngularFirestore, afStorage: AngularFireStorage, ngxLogger: NGXLogger, reference, field: string, nestedReference?) => {
    return (source) =>
        defer(() => {
            // Operator state
            let collectionData;
            const cache = new Map();

            return source.pipe(
                switchMap((data) => {
                    // Clear mapping on each emitted val ;
                    cache.clear();

                    // Save the parent data state
                    collectionData = data as any[];

                    const reads$ = [];
                    let storageUrlPath = of([]);
                    let i = 0;

                    if (nestedReference) {
                        // Need refactoring, way too ugly,  must use getProps instead.
                        for (const doc of collectionData) {
                            // Skip if doc field does not exist or is already in cache
                            if (
                                doc[reference] === undefined ||
                                doc[reference] === null ||
                                doc[reference] === '' ||
                                doc[reference][nestedReference] === undefined ||
                                doc[reference][nestedReference] === null ||
                                doc[reference][nestedReference] === '' ||
                                cache.get(doc[reference][nestedReference])
                            ) {
                                continue;
                            }

                            if (doc[reference][nestedReference]) {
                                const storagePath = afs.doc(doc[reference][nestedReference]).ref.path;
                                storageUrlPath = afStorage.ref(storagePath).getDownloadURL();
                            }

                            // Push doc read to Array
                            reads$.push(storageUrlPath);
                            cache.set(doc[reference][nestedReference], i);
                            i++;
                        }
                    } else {
                        for (const doc of collectionData) {
                            // Skip if doc field does not exist or is already in cache
                            if (doc[reference] === undefined || doc[reference] === null || doc[reference] === '' || cache.get(doc[reference])) {
                                continue;
                            }

                            if (doc[reference]) {
                                const storagePath = afs.doc(doc[reference]).ref.path;
                                storageUrlPath = afStorage.ref(storagePath).getDownloadURL();
                            }

                            // Push doc read to Array
                            reads$.push(storageUrlPath);
                            cache.set(doc[reference], i);
                            i++;
                        }
                    }

                    return reads$.length ? combineLatest(reads$) : of([]);
                }),
                map((joins) => {
                    if (nestedReference) {
                        return collectionData.map((v, i) => {
                            if (
                                v[reference] === undefined ||
                                v[reference] === null ||
                                v[reference] === '' ||
                                v[reference][nestedReference] === undefined ||
                                v[reference][nestedReference] === null ||
                                v[reference][nestedReference] === ''
                            ) {
                                return { ...v };
                            }

                            const joinIdx = cache.get(v[reference][nestedReference]);
                            return {
                                ...v,
                                [reference]: { ...v[reference], [field]: joins[joinIdx] || null },
                            };
                        });
                    } else {
                        return collectionData.map((v, i) => {
                            if (v[reference] === undefined || v[reference] === null || v[reference] === '') {
                                return { ...v };
                            }

                            const joinIdx = cache.get(v[reference]);
                            return { ...v, [field]: joins[joinIdx] || null };
                        });
                    }
                }),
                tap((final) => ngxLogger.trace(`\nQueries executadas: ${(final as any).length}\nJoins realizados: ${cache.size}\nCampo: ${field}`)),
            );
        });
};
