import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalService } from 'app/core/services/global.service';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
    private globalService: GlobalService
  ) {}

  @ViewChild('emailInput', { static: true }) emailInput: ElementRef<
    HTMLInputElement
  >;

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.emailInput.nativeElement.focus();
  }

  private loginOkNotification() {
    this.snackBar.open(
      'Logado com sucesso, bem vindo !',
      'OK',
      { duration: 3000 }
    );
  }

  private loginErrorNotification() {
    this.snackBar.open(
      'Credenciais inválidas ou usuario não cadastrado | No caso de duvidas entre em contato com nosso suporte.',
      'OK',
      { duration: 5000 }
    );
  }

  clear() {
    this.loginForm.controls['password'].setValue('');
  }

  async onSubmit() {
    this.loading = true;
    this.authService
      .loginEmailESenha(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        res => {
          this.loginOkNotification();
          this.router.navigate(['/main']);
          this.loading = false;
        },
        err => {
          this.loginErrorNotification();
          this.loading = false;
          this.clear();
          this.emailInput.nativeElement.focus();
        }
      );
  }
}
