import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { AngularValidateBrLibModule } from 'angular-validate-br';
import { NgxMaskModule } from 'ngx-mask';

import { MaterialModule } from '../material.module';
import { ForgotpasswordComponent } from './forgot-password/forgotpassword.component';
import { LoginComponent } from './login/login.component';
import { MetodoLoginComponent } from './metodo-login/metodo-login.component';

@NgModule({
  declarations: [LoginComponent, ForgotpasswordComponent, MetodoLoginComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    FuseSharedModule,
    FlexLayoutModule,
    AngularValidateBrLibModule,

    NgxMaskModule.forChild()
  ]
})
export class AuthModule {}
