import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 *  Serviço destinado ao tratamento de imagens capturadas pela api de camera do flit web.
 *
 * @export
 * @class ImageService
 */
@Injectable({
    providedIn: 'root',
})
export class ImageService {
    constructor(private ngxLogger: NGXLogger) {
        this.ngxLogger.info(`\nConstruindo ImageService.`);
    }

    /**
     *  Converter data URI to blob.
     *
     * @param {*} dataURI
     * @param {string} [type='image/jpeg']
     * @return {*}  {Blob}
     * @memberof ImageService
     */
    public dataURItoBlob(dataURI): Blob {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    public blobToBase64(blob: Blob): Promise<string | ArrayBuffer> {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function (): void {
                    const base64data = (reader.result as string).split(',')[1];
                    return resolve(base64data);
                };
            } catch (error) {
                reject(error);
            }
        });
    }

    /**
     *  Função destinada a comprimir a imagem de acordo com a necessidade, o padrão é 300 x 300.
     *
     * @param {string} src
     * @param {number} newX
     * @param {number} newY
     * @return {*}  {Promise<string>}
     * @memberof ImageService
     */
    public compressImage(src: string, newX: number = 300, newY: number = 300): Promise<string> {
        this.ngxLogger.info(`\nFunção ImageService => compressImage executada.\nParâmetros:`, { src, newX, newY });
        return new Promise((res, rej) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                const elem = document.createElement('canvas');
                elem.width = newX;
                elem.height = newY;
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, newX, newY);
                const data = ctx.canvas.toDataURL();
                res(data);
            };
            img.onerror = (error) => rej(error);
        });
    }
}
