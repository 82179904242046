import { DocumentReference } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { isNullOrUndefined } from 'util';

export class MarcacaoFlitWebModel {
    appinfo: {
        code: number;
        name: string;
        so: string;
        version: string;
    } = { code: null, name: null, so: null, version: null };
    datahora: firebase.firestore.Timestamp | Date | number = null;
    /* data_hora_true_time: firebase.firestore.Timestamp | firestore.FieldValue = null;
  datahora_sync: firebase.firestore.Timestamp | Date | firestore.FieldValue = null; */
    dispositivo: {
        device: string;
        device_type: string;
        date: firebase.firestore.Timestamp | Date | number;
        timezone_value: string;
        timezone_value_name: string;
        browser: string;
        browser_version: string;
        os_version: string;
        userAgent: string;
    } = {
        device: null,
        device_type: null,
        date: null,
        timezone_value: null,
        timezone_value_name: null,
        browser: null,
        browser_version: null,
        os_version: null,
        userAgent: null,
    };
    empresa_ref: DocumentReference | string = null;
    foto_url: string = null;
    foto_url_upload_pendente: boolean = null;
    jornada_ref: DocumentReference = null;
    localizacao: {
        altitude?: number;
        coordenada?: firebase.firestore.GeoPoint;
        datahora?: firebase.firestore.Timestamp | Date | number;
        endereco?: {
            bairro?: string;
            cep?: string;
            cidade?: string;
            estado?: string;
            logradouro?: string;
            numero?: string;
            pais?: string;
        };
        latitude?: number;
        longitude?: number;
        precisao?: number;
        precisao_altitude?: number;
        precisao_velocidade?: number;
        provider?: string | 'FIXO';
        velocidade?: number;
    } = {
        altitude: null,
        coordenada: null,
        datahora: null,
        endereco: {
            bairro: null,
            cep: null,
            cidade: null,
            estado: null,
            logradouro: null,
            numero: null,
            pais: null,
        },
        latitude: null,
        longitude: null,
        precisao: null,
        precisao_altitude: null,
        precisao_velocidade: null,
        provider: null,
        velocidade: null,
    };
    parametros: {
        fotografar: boolean;
        online: boolean;
        reconhecimento_facial: boolean;
        reconhecimento_facial_obrigatorio: boolean;
    } = {
        fotografar: null,
        online: null,
        reconhecimento_facial: null,
        reconhecimento_facial_obrigatorio: null,
    };
    reconhecimento_facial: {
        confiabilidade: number;
        similaridade: number;
        online: boolean;
        valido: boolean;
    } = {
        confiabilidade: null,
        similaridade: null,
        online: null,
        valido: null,
    };
    terminal_ref?: DocumentReference | string | null = null;
    true_time: boolean = null;
    usuario_ref: DocumentReference | string = null;

    constructor(payload?: Partial<MarcacaoFlitWebModel>) {
        const data = payload || {};
        for (const key in this) {
            const string_key = key.toString();
            const value = payload[string_key];
            if (!isNullOrUndefined(value)) {
                this[key] = value;
            }
        }
    }
}
