import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  deviceInfo: DeviceInfo;

  constructor(private deviceService: DeviceDetectorService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  retornarTipoDeDispositivo() {
    const isDesktopDevice = this.deviceService.isDesktop();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (isDesktopDevice) {
      return 'Desktop';
    }
    if (isMobile) {
      return 'Mobile';
    }
    if (isTablet) {
      return 'Tablet';
    }
    return 'Unknown';
  }

  retornarAppInfo(){
      return{
          code: environment.buildversion,
          name: environment.empresaAppName,
          version: environment.version,
          so: this.deviceInfo.os
      }
  }

  retornarDadosDispositivo() {
    return {
      device: this.deviceInfo.device,
      device_type: this.retornarTipoDeDispositivo(),
      browser: this.deviceInfo.browser,
      browser_version: this.deviceInfo.browser_version,
      os_version: this.deviceInfo.os_version,
      userAgent: this.deviceInfo.userAgent,
      date: new Date().getTime(),
      timezone_value: moment(new Date()).format('Z'),
      timezone_value_name: new Date().toString().match(/\(([^)]+)\)/)['1']
    };
  }
}
