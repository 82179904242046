import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Empresa } from '@shared/models/contas/empresa.model';
import { EmpresaModel } from 'app/empresa/models/empresa-fire.model';
import { ContaFireModel } from 'app/models/conta-fire.model';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

/**
 * Serviço global iniciado com o APP.
 *
 * @export
 * @class GlobalService
 * @implements {OnDestroy}
 */
@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    docUsuarioLogado;
    uidUsuarioLogado;
    emailUsuarioLogado;

    contaRef: string;
    contaId: string;
    contaPath: string;
    escalaRef: string;
    escalaData: [];
    unsubscribeAll: Subject<any> = new Subject();
    externalIp: any;
    empresa$: Observable<Empresa>;

    /**
     * Cria uma instância de GlobalService.
     * @param {AngularFireAuth} afAuth
     * @param {AngularFirestore} afs
     * @param {Router} router
     * @param {ToastrService} _toastr
     * @param {NGXLogger} ngxLogger
     * @memberof GlobalService
     */
    constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router, private _toastr: ToastrService, private ngxLogger: NGXLogger) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos públicos
    // -----------------------------------------------------------------------------------------------------
    /**
     * Setar dados do login.
     *
     * @memberof GlobalService
     */
    setAccountData(): void {
        this.ngxLogger.info(`\nFunção setAccountData executada.\nParâmetros:`, {});
        this.afAuth.onAuthStateChanged((user) => {
            if (user && localStorage.getItem('metodo-login') === 'colaborador') {
                this.uidUsuarioLogado = user.uid;
                // console.log(user.uid)
                this.docUsuarioLogado = this.afs.doc(`usuarios/${user.uid}`).valueChanges();
                this.emailUsuarioLogado = user.email;

                this.afs
                    .doc(`usuarios/${user.uid}`)
                    .snapshotChanges()
                    .pipe(
                        takeUntil(this.unsubscribeAll),
                        tap((userDocSnap) => {
                            const usuario_status = userDocSnap.payload.get('status');
                            const usuario_excluido: boolean = userDocSnap.payload.get('excluido');
                            if (usuario_status === 'Demitido' || usuario_excluido === true) {
                                this.unsubscribeAll.next();
                                this.unsubscribeAll.complete();
                                this.afAuth.signOut();
                                this.router.navigateByUrl('/auth/metodo-login');
                                return false;
                            }
                        }),
                    )
                    .subscribe(
                        (doc: any) => {
                            try {
                                this.contaId = doc.payload.data().conta_ref.id;
                                this.contaRef = doc.payload.data().conta_ref || null;
                                this.contaPath = doc.payload.data().conta_ref.path;
                                this.empresa$ = this.afs
                                    .doc<Empresa>(doc.payload.data().empresa_ref.path)
                                    .valueChanges({ idField: 'uid' })
                                    .pipe(
                                        map((doc) => {
                                            doc.path = `contas/${this.contaId}/empresas/${doc.uid}`;
                                            return doc;
                                        }),
                                    )
                                    .pipe(takeUntil(this.unsubscribeAll));

                                /**
                                 * @description {{EMPRESA E CONTA LOGIN LISTENERS VERSAO 2.0.0 O IDEAL É REFATORAR A ESTRUTURA DA MARCACAO INDIVIDUAL DEPOIS}}
                                 */
                                this.afs
                                    .doc<ContaFireModel>(this.contaRef)
                                    .valueChanges()
                                    .pipe(takeUntil(this.unsubscribeAll))
                                    .subscribe(
                                        (conta) => {
                                            this.verificarConta(conta);
                                        },
                                        (err) => {
                                            this.ngxLogger.error(`\nErro na promise da função setAccountData.\n`, err);
                                        },
                                    );
                                this.afs
                                    .doc<EmpresaModel>(doc.payload.data().empresa_ref.path)
                                    .valueChanges()
                                    .pipe(takeUntil(this.unsubscribeAll))
                                    .subscribe(
                                        (empresa) => {
                                            this.verificarEmpresa(empresa);
                                        },
                                        (err) => {
                                            this.ngxLogger.error(`\nErro na promise da função setAccountData.\n`, err);
                                        },
                                    );
                            } catch (error) {
                                this.verificarConta({ excluido: true });
                            }
                        },
                        (error) => {
                            this.ngxLogger.error(`\nErro na promise da função setAccountData.\n`, error);
                        },
                    );
            }
        });
    }

    /**
     * Deslogar dispositivo da empresa.
     *
     * @param {*} [message]
     * @param {*} [title]
     * @returns {boolean}
     * @memberof GlobalService
     */
    deslogarDispositivo(message?, title?): boolean {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        if (message && title) {
            this._toastr.warning(message, title, { timeOut: 10000 });
        }
        this.afAuth.signOut();
        this.router.navigateByUrl('/auth/metodo-login');
        return false;
    }

    /**
     * Verificar permissões de usuario.
     *
     * @param {string} userId
     * @returns {Promise<boolean>}
     * @memberof GlobalService
     */
    async verificarPermissoes(userId: string): Promise<boolean> {
        const usuario = await this.afs.doc(`usuarios/${userId}`).get().pipe(take(1)).toPromise();
        const usuario_status = usuario.get('status');
        const usuario_excluido: boolean = usuario.get('excluido');

        if (usuario_status === 'Demitido' || usuario_excluido === true) {
            return false;
        }

        return true;
    }

    /**
     * Gerar dispositivo UID.
     *
     * @memberof GlobalService
     */
    gerarDispositivoUid(): void {
        const key = 'di';
        if (!localStorage.getItem(key)) {
            localStorage.setItem(key, this.afs.createId());
        }
    }

    /**
     * Função para remover inscrições internas do GlobalService.
     * Obs: Esta função pode ser chamada sempre que um fluxo de login for terminar,
     * mas não necessariamente precisa estar dentro do logout.
     * @memberof GlobalService
     */
    removerInscricoesDoGlobalService(): void {
        // Fazendo isso evita estourar o erro do firebase sobre a função setAccountData.
        this.ngxLogger.info(`\nFunção removerInscricoesDoGlobalService executada.\nParâmetros:`, {});
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos privados
    // -----------------------------------------------------------------------------------------------------

    /**
     * Verificar se a conta foi exclusa e deslogar dispositivo.
     *
     * @private
     * @param {Partial<ContaFireModel>} conta
     * @memberof GlobalService
     */
    private verificarConta(conta: Partial<ContaFireModel>): void {
        if (conta.excluido) {
            this.deslogarDispositivo('Conta desativada', 'Aviso');
        }
    }

    /**
     * Verificar a empresa e deslogar dispositivo.
     *
     * @private
     * @param {EmpresaModel} empresa
     * @memberof GlobalService
     */
    private verificarEmpresa(empresa: EmpresaModel): void {
        if (empresa.excluido) {
            this.deslogarDispositivo('Empresa inativa', 'Aviso');
        }
    }
}
