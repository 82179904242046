<div class="marcacao-container" *ngIf="parametroMarcarWeb; else naopermitido">
    <div #marcacao id="marcacao">
        <div class="mat-dialog-header">
            <div class="logo">
                <img src="assets/images/logos/flit_logo_color_w128.png" />
            </div>
            <h1 mat-dialog-title class="margin-zero">Nova marcacão</h1>
        </div>
        <div class="webcam-container" *ngIf="showWebcam">
            <webcam
                [width]="273"
                [height]="205"
                [imageQuality]="1"
                [trigger]="triggerObservable"
                (initError)="handleInitError($event)"
                (imageCapture)="handleImage($event)"
            ></webcam>
        </div>
        <div class="webcam-error-container" *ngIf="errors.length > 0">
            <div>
                <p>Não foi possivel acessar a sua camera.</p>
                <button
                    mat-icon-button
                    #tooltip="matTooltip"
                    matTooltip="-Verificar sua webcam e Recarregar a pagina.&#13;
              Caso persista:&#13;
            -Verifique se a camera esta sendo permitida em seu navegador&#13;"
                    (click)="tooltip.show()"
                    aria-label="Button that displays a tooltip when focused or hovered over"
                    matTooltipPosition="right"
                    [matTooltipClass]="'tooltip'"
                    matTooltipHideDelay="5000"
                >
                    <mat-icon color="warn" class="icon-error">help_outline </mat-icon>
                </button>
            </div>
        </div>

        <mat-dialog-content class="mat-typography">
            <p class="margin-zero secondary-text">Usuário:</p>
            <p class="text-marcacao-nome">{{ (user$ | async)?.nomeCompleto }}</p>

            <p class="margin-zero secondary-text">Data:</p>

            <div class="container-data">
                <div
                  style="max-width: 120px;"
                    *ngIf="exibirDataEHoraContainer; else dataCarregando"
                    matTooltip="As suas marcações foram configuradas em um fuso horário diferente do que esta no seu computador, em caso de duvidas verifique com o seu gestor."
                    [matTooltipClass]="'tooltip-warn'"
                    [matTooltipDisabled]="timezoneValueName"
                    [matTooltipPosition]="'right'"
                >
                    <p class="margin-zero text-marcacao-data">{{ diaMesAno }}</p>
                    <p class="margin-zero">
                        <span class="text-marcacao-destaque">{{ hora }}</span>
                        <span class="text-marcacao-secondary secondary-text" [ngStyle]="{ color: !timezoneValueName ? '#ff6d00' : '' }">UTC{{ timezone }}</span>
                    </p>
                </div>
                <ng-template #dataCarregando>
                    <div style="height: 41.6px; width: 50% !important" fxLayoutAlign="center center">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <!--  <mat-spinner
          class="spinner-container"
          strokeWidth="3"
          [diameter]="30"
        ></mat-spinner> -->
                </ng-template>
            </div>
        </mat-dialog-content>
    </div>

    <mat-dialog-actions>
        <button mat-button mat-stroked-button color="warn" [mat-dialog-close]="false">
            <span
                >Cancelar<span>({{ counter$ | async }})</span></span
            >
        </button>
        <button (click)="onBtnMarcarClick()" mat-button mat-stroked-button cdkFocusInitial color="primary">
            <ng-container *ngIf="botaoConfirmar; else loading">
                <span
                    *ngIf="
                        !passedData.usuario.parametros.reconhecimento_facial ||
                            !(this.retornarUltimaLicencaContaDocSnap(passedData.contaDocData)['reconhecimento_facial'] || false);
                        else reconhecimentoFacial
                    "
                    >Confirmar</span
                >
                <ng-template #reconhecimentoFacial>
                    <span fxLayoutAlign="center center" style="height: 35px !important">
                        <span style="padding-right: 2px !important">Reconhecer</span>
                        <mat-icon>face</mat-icon>
                    </span>
                </ng-template>
            </ng-container>
            <ng-template #loading>
                <div style="width: 100%" fxLayoutAlign="center center">
                    <mat-spinner class="spinner-container" strokeWidth="3" [diameter]="30"></mat-spinner>
                </div>
            </ng-template>
        </button>
        <!--  <ng-template #loading>
      <button mat-button mat-stroked-button color="primary" fxLayoutAlign="center center">
      </button>
    </ng-template> -->
    </mat-dialog-actions>
</div>

<ng-template #naopermitido>
    <div>
        <div class="mat-dialog-header">
            <div class="logo">
                <img src="assets/images/logos/flit_logo_color_w128.png" />
            </div>
        </div>
        <mat-dialog-content class="mat-typography">
            <div fxLayoutAlign="center center">
                <mat-icon class="s-60 icon-warning-2">warning</mat-icon>
            </div>
            <h3 fxLayoutAlign="center">Recurso indisponível para seu usuário.</h3>
            <h4 class="secondary-text">Caso necessite acesso, verifique com seu gestor.</h4>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-stroked-button color="warn" [mat-dialog-close]="false">
                <span>Sair</span>
            </button>
        </mat-dialog-actions>
    </div>
</ng-template>
