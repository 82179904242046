import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AngularFireFunctions } from '@angular/fire/functions';
import { take } from 'rxjs/operators';

/**
 * Serviço para buscar data de hora da API.
 *
 * @export
 * @class DataHoraGlobalService
 */
@Injectable({
  providedIn: 'root'
})
export class DataHoraGlobalService {
  /**
   * Cria instancia de DataHoraGlobalService.
   * @param {NGXLogger} ngxLogger
   * @param {AngularFireFunctions} fns
   * @memberof DataHoraGlobalService
   */
  constructor(
    private ngxLogger: NGXLogger,
    private fns: AngularFireFunctions
  ) {}
  // -----------------------------------------------------------------------------------------------------
  // @ Métodos públicos
  // -----------------------------------------------------------------------------------------------------

  /**
   * Função para retornar data e hora global com base em uma API
   *
   * @returns {Promise<{ data: string; millis: number }>}
   * @memberof DataHoraGlobalService
   */
  async retornarDataHoraGlobal(): Promise<{ data: string; millis: number }> {
    try {
      this.ngxLogger.info(
        `\nFunção retornarDataHoraGlobal executada.\nParâmetros:`,
        {}
      );
      const callable_data_atual = this.fns.httpsCallable<
        {},
        { data: string; millis: number }
      >('data_atual');
      
      const data$ = callable_data_atual({});

      const resposta = await data$.pipe(take(1)).toPromise();

      return resposta;
    } catch (erro) {
      this.ngxLogger.error(
        `\nErro na promise da função retornarDataHoraGlobal.\n`,
        erro
      );
    }
  }
}
