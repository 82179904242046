import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ValidationPatternAndMaskService {
    public cnpjPattern =
        '([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})';
    public cnpjMask = '00.000.000/0000-00';

    public cpfPattern =
        '([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})';
    public cpfMask = '000.000.000-00';

    public companyCpfMask = '000.000.000-009';

    public pisPattern = '([0-9]{3}[.]?[0-9]{5}[.]?[0-9]{2}[.]?[0-9]{1})';
    public pisMask = '000.00000.00.0';

    public textSpacePattern = '[a-zA-ZÀ-ÿ ]*';
    public textSpacePatternMask = { '0': { pattern: new RegExp(/[a-zA-ZÀ-ÿ ]/) } };

    public textSpaceNumberPattern = '[a-zA-Z0-9À-ÿ ]*';
    public textSpaceNumberPatternMask = { '0': { pattern: new RegExp(/[a-zA-Z0-9À-ÿ ]/) } };

    public textSpaceNumberExtraPattern = '^[-.& ()a-zA-ZÀ-ÿ0-9]+$';
    public textSpaceNumberExtraPatternMask = {
        '0': { pattern: new RegExp(/^[-.& ()a-zA-ZÀ-ÿ0-9]+$/) }
    };

    public numberPattern = '[0-9 ]*';

    public phonePattern = /^(?=.*[0-9])[- ()0-9]+$/;
    public phoneMask = '(00) 0000-00009';

    public cellphonePattern = /^(?=.*[0-9])[- ()0-9]+$/;
    public cellphoneMask = '(00) 00000-0000';

    public cepPattern = /^(?=.*[0-9])[- 0-9]+$/;
    public cepMask = '00000-000';

    public hourPattern = '^[:0-9]+$';
    public hourMask = 'Hh:s0';

    public dateMask = 'd0/m0/0000';


    public emailPattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"

    constructor() {}
}
