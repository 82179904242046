import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ContaFireModel } from 'app/models/conta-fire.model';
import { User } from 'app/models/user.model';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface ReconhecimentoFacialResposta {
  id: string;
  confidence: number;
  similarity: number;
  valid: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ReconhecimentoFacialService {
  constructor(private fns: AngularFireFunctions) {}

  verificarContaEUsuario(conta: ContaFireModel, usuario: User): boolean {
    if (!conta.licencas.length) {
      return false;
    }
    const conta_rf = (
      conta.licencas[conta.licencas.length - 1] || {
        reconhecimento_facial: false
      }
    ).reconhecimento_facial;
    const usuario_rf = (usuario.parametros || { reconhecimento_facial: false })
      .reconhecimento_facial;
    if (conta_rf && usuario_rf) {
      return true;
    }
    return false;
  }

  async verificarFace(contaId: string, foto: string) {
    /* console.log(contaId);
    console.log(foto); */
    const callable = this.fns.httpsCallable('rf_pesquisar');
    const data$: Observable<ReconhecimentoFacialResposta> = callable({ contaId, foto });
    return await data$
      .pipe(
        catchError(err => {
          return of({ id: null, confidence: 0, similarity: 0, valid: false });
        })
      )
      .toPromise();
  }

  confirmarSeEaFaceDoUsuarioLogado(face_id:ReconhecimentoFacialResposta, usuario: User): boolean {
    if(face_id.id === usuario.uid && face_id.valid){
      return true
    } else {
      return false;
    }
  }
}
