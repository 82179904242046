import { Injectable, NgZone } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanLoad } from '@angular/router';
import { AuthService } from './auth.service';
import { tap, take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {
    constructor(private authService: AuthService, private router: Router, private ngxLogger: NGXLogger, private ngZone: NgZone) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // console.log('passou aqui')
        return this.authService.isLoggedIn().pipe(
            tap((permitido) => {
                if (!permitido) {
                    this.ngxLogger.info(`\nAccesso negado AuthGuard (canActivate).\nParâmetros:`, { permitido });
                    this.router.navigate(['auth', 'metodo-login']);
                }
            }),
        );
    }

    canLoad(route: Route): Observable<boolean> {
        /* console.log('canLoad'); */
        return this.authService.isLoggedIn().pipe(
            take(1),
            tap((loggedIn) => {
                if (!loggedIn) {
                    this.ngxLogger.info(`\nAccesso negado AuthGuard (canLoad).\nParâmetros:`, { loggedIn });
                    this.router.navigateByUrl('/auth/metodo-login');
                }
            }),
        );
    }
}
