import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NGXLogger } from 'ngx-logger';
import { take } from 'rxjs/operators';
import { ComprovantePontoDialogComponent } from './comprovante-ponto-dialog.component';
import { Usuario } from '@shared/models/usuario.model';
import { MarcacaoModel } from '@shared/models/marcacao.model';

/**
 * Serviço helper para quem importar o Modulo: ComprovantePontoDialogModule.
 *
 * @export
 * @class ComprovantePontoDialogService
 */
@Injectable({ providedIn: 'root' })
export class ComprovantePontoDialogService {
    // <-- Propriedades privadas
    private comprovanteDialogRef: MatDialogRef<ComprovantePontoDialogComponent, any>;
    // Propriedades privadas -->

    constructor(private ngxLogger: NGXLogger, private matDialog: MatDialog, private angularFireFunctions: AngularFireFunctions) {}

    /**
     * Helper para abrir o comprovante do dialog de forma correta.
     *
     * @param {Partial<Usuario>} usuario
     * @param {MarcacaoModel} marcacao
     * @returns {MatDialogRef<ComprovantePontoDialogComponent, any>}
     * @memberof ComprovantePontoDialogService
     */
    abrirDialogComprovante(usuario: Partial<Usuario>, marcacao: MarcacaoModel, modo?: 'primeira_abertura'): MatDialogRef<ComprovantePontoDialogComponent, any> {
        this.ngxLogger.info(`\nFunção abrirDialogComprovante executada.\nParâmetros:`, { usuario, marcacao, modo });
        return (this.comprovanteDialogRef = this.matDialog.open(ComprovantePontoDialogComponent, {
            maxHeight: '90vh',
            panelClass: 'comprovante-ponto-dialog',
            data: {
                usuario,
                marcacao,
                modo
            },
        }));
    }

    /**
     * Função para Buscar Token do comprovante da marcação na cloud function.
     *
     * @param {{ appName: string; appVersion: string; datahora: number; id: string; userId: string }} dadosMarcacao
     * @returns {Promise<{ token: string; erro?: string }>}
     * @memberof ComprovantePontoDialogService
     */
    async buscarToken(dadosMarcacao: { appName: string; appVersion: string; datahora: number; id: string; userId: string }): Promise<{ token: string; erro?: string }> {
        this.ngxLogger.info(`\nFunção buscarToken executada.\nParâmetros:`, { dadosMarcacao });
        try {
            const chamadaHttps = this.angularFireFunctions.httpsCallable<
                {
                    appName: string;
                    appVersion: string;
                    datahora: number;
                    id: string;
                    userId: string;
                },
                { token: string }
            >('gerar_token_comprovante');
            const dados$ = chamadaHttps(dadosMarcacao);
            const respostaFuncao = await dados$.pipe(take(1)).toPromise();
            return respostaFuncao;
        } catch (erro) {
            this.ngxLogger.error(`\nErro na promise da função buscarToken.\n`, erro);
            return { token: '', erro: erro.message };
        }
    }
}
