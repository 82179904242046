import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentData, DocumentSnapshot } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DocumentReference } from '@firebase/firestore-types';
import { MarcacaoModel } from '@shared/models/marcacao.model';
import { ComprovantePontoDialogService } from 'app/comprovante-ponto-dialog/comprovante-ponto-dialog.service';
import { UsuarioLogadoService } from 'app/usuario/usuario-logado.service';
import { environment } from 'environments/environment';
import firebase from 'firebase/app';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { GlobalService } from '../core/services/global.service';
import { MarcacaoFlitWebModel } from '../empresa/models/marcacao-tab-fire.model';
import { User } from '../models/user.model';
import { LoadingDialogComponent } from '../shared/components/loading-dialog/loading-dialog.component';
import { ReconhecimentoFacialResposta } from '../shared/services/reconhecimento-facial.service';
import { UtilitariosService } from '../shared/services/utilitarios.service';
import { MarcacaoComponent } from './marcacao/marcacao.component';
import { format as formatDate } from 'date-fns';
import { LocalStorageLog } from '@shared/helpers/local-storage-log';
import { uniqueId } from 'lodash';
import { ImageService } from '@shared/services/image.service';
import { Ng2ImgMaxService } from 'ng2-img-max';

/**
 * Classe que envolve a marcação de pontos e sua dependências.
 * TODO: GD refatorar.
 * @export
 * @class MarcarpontoService
 * @implements {Resolve<any>}
 */
@Injectable()
export class MarcarpontoService {
    usertoken: string;
    marcarPontoDialogRef: MatDialogRef<MarcacaoComponent>;
    evitarDuplicarAberturas = false;

    constructor(
        private dialog: MatDialog,
        private afs: AngularFirestore,
        private globalService: GlobalService,
        private deviceService: DeviceDetectorService,
        private _toastr: ToastrService,
        private afAuth: AngularFireAuth,
        private fns: AngularFireFunctions,
        private utilitariosService: UtilitariosService,
        private ngxLogger: NGXLogger,
        private comprovantePontoDialogService: ComprovantePontoDialogService,
        private usuarioLogadoService: UsuarioLogadoService,
        private imageService: ImageService,
        private ng2ImgMaxService: Ng2ImgMaxService,
    ) {
        this.afAuth.idToken.subscribe((token) => {
            this.usertoken = token;
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Métodos públicos
    // -----------------------------------------------------------------------------------------------------

    /**
     * Abrir dialog de confirmar marcação.
     * TODO: GD Refatorar.
     *
     * @param {Observable<User>} user
     * @param {Position} [position]
     * @returns {(Promise<Subscription | MatDialogRef<MarcacaoComponent, any>>)}
     * @memberof MarcarpontoService
     */
    async abrirConfirmacaoMarcacao(user: Observable<User>, position?: GeolocationPosition): Promise<Subscription | MatDialogRef<MarcacaoComponent, any>> {
        if (this.evitarDuplicarAberturas === false && this.usuarioLogadoService.usuarioLogado$.getValue().conta !== undefined) {
            this.evitarDuplicarAberturas = true;
            let usuario: User = await user.pipe(take(1)).toPromise();
            try {
                if (usuario.parametros.marca_ponto_web === false) {
                    return this.dialog
                        .open(MarcacaoComponent, {
                            maxHeight: '90vh',
                            data: {},
                        })
                        .afterClosed()
                        .subscribe(() => {
                            this.evitarDuplicarAberturas = false;
                        });
                }
            } catch (error) {
                return this.dialog
                    .open(MarcacaoComponent, {
                        maxHeight: '90vh',
                        data: {},
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.evitarDuplicarAberturas = false;
                    });
            }
            usuario = new User({ ...usuario });
            // console.warn(this.dialog.openDialogs.length);
            if (this.dialog.openDialogs.length === 0) {
                this.marcarPontoDialogRef = this.dialog.open(MarcacaoComponent, {
                    maxHeight: '90vh',
                    disableClose: true,
                    autoFocus: true,
                    data: {
                        contaDocData: this.usuarioLogadoService.usuarioLogado$.getValue().conta,
                        user,
                        usuario,
                        position,
                    },
                });
                this.evitarDuplicarAberturas = false;

                return this.marcarPontoDialogRef
                    .afterClosed()
                    .pipe(take(1))
                    .subscribe((confirmaMarcacao) => {
                        if (confirmaMarcacao === false || confirmaMarcacao === undefined) {
                            return null;
                        } else {
                            this.marcarPontoWeb(confirmaMarcacao);
                        }
                    });
            }
        }
    }

    /**
     * Função para marcar ponto no flit web.
     * TODO: GD Refatorar.
     *
     * @param {*} passedData
     * @returns {Promise<ActiveToast<any>>}
     * @memberof MarcarpontoService
     */
    async marcarPontoWeb(passedData: any): Promise<any> {
        const datahoraOpen: Date = passedData.dataHoraGlobalDialogAberto;
        LocalStorageLog.gravarArrayDeLogs<string>({
            buffer_length: 200,
            key_name: 'monitor_marcarpontoweb',
            data: `id: 1, message: "Botão de confirmar marcação pressionado", data_proposta: "${formatDate(datahoraOpen, 'dd/MM/yyyy HH:mm:ss')}", next: 2`,
        });
        try {
            const position: GeolocationPosition = passedData.position;
            const timezone: string = passedData.timezone;
            const timezoneName: string = passedData.timezoneValueName;
            const user$: Observable<any> = passedData.user;
            const userUid: Observable<any> = this.globalService.uidUsuarioLogado;
            const userArray: Array<User> = new Array();
            const webcamImage: WebcamImage = passedData.webcamImage;
            const fotografar: boolean = passedData.parametroFotografar;
            const face_id: ReconhecimentoFacialResposta = passedData.face_id;

            const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();

            await user$.pipe(take(1)).forEach((a) => userArray.push(a));

            const ultimaMarcacaoArray: Array<any> = new Array();

            await this.afs
                .collection(`${userArray[0].conta_ref.path}/marcacoes`, (ref) =>
                    ref
                        .orderBy('datahora', 'desc')
                        .where('usuario_ref', '==', this.afs.doc(`usuarios/${userUid}`).ref)
                        .limit(1),
                )
                .get()
                .toPromise()
                .then((ult) => {
                    if (ult.docs.length) {
                        ultimaMarcacaoArray.push({ uid: ult.docs[0].id, ref: ult.docs[0].ref, ...(ult.docs[0].data() as {}) });
                    }
                });

            try {
                const ultimaMarcacao = moment.utc(ultimaMarcacaoArray[0]?.datahora?.toDate()).utcOffset(ultimaMarcacaoArray[0]?.dispositivo?.timezone_value);
                const difultimaMarcacao = Math.abs(ultimaMarcacao.diff(datahoraOpen, 'minutes'));
                // console.warn({ ultimaMarcacaoArray, ultimaMarcacao, difultimaMarcacao });

                if (difultimaMarcacao === 0) {
                    return this._toastr.warning('Necessário aguardar mais um minuto antes de realizar uma nova marcação.', '', {
                        timeOut: 30000,
                        positionClass: 'toast-top-center',
                    });
                }
            } catch (error) {
                // Primeira Marcação
                this.ngxLogger.info(`\n Primeira marcação ainda não tinha sido feita.\n`, error);
            }

            const loadingDialog = this.dialog.open(LoadingDialogComponent, {
                disableClose: true,
            });

            const dispositivoData = moment.utc(new Date()).toDate().getTime();

            const dadosMarcacao: MarcacaoFlitWebModel = new MarcacaoFlitWebModel({
                appinfo: {
                    code: environment.buildversion,
                    name: environment.appName,
                    version: environment.version,
                    so: deviceInfo.os,
                },
                datahora: datahoraOpen.getTime(),
                dispositivo: {
                    device: deviceInfo.device,
                    device_type: this.retornarTipoDeDispositivo(),
                    browser: deviceInfo.browser,
                    browser_version: deviceInfo.browser_version,
                    os_version: deviceInfo.os_version,
                    userAgent: deviceInfo.userAgent,
                    date: dispositivoData,
                    timezone_value: timezone,
                    timezone_value_name: timezoneName,
                },
                empresa_ref: userArray[0].empresa_ref.path,
                foto_url: null,
                foto_url_upload_pendente: false,
                parametros: {
                    fotografar: _.isNil(fotografar) || fotografar === false ? false : true,
                    online: true,
                    reconhecimento_facial: (userArray[0].parametros || { reconhecimento_facial: undefined }).reconhecimento_facial === true ? true : false,
                    reconhecimento_facial_obrigatorio:
                        (
                            userArray[0].parametros || {
                                reconhecimento_facial_obrigatorio: undefined,
                            }
                        ).reconhecimento_facial_obrigatorio === true
                            ? true
                            : false,
                },
                reconhecimento_facial: face_id
                    ? {
                          confiabilidade: face_id.confidence,
                          similaridade: face_id.similarity,
                          online: true,
                          valido: face_id.valid,
                      }
                    : { confiabilidade: 0, similaridade: 0, online: true, valido: false },
                true_time: true,
                usuario_ref: this.afs.doc(`usuarios/${userUid}`).ref.path,
                localizacao: this.utilitariosService.validarCoordenadasDePosicao(position.coords.latitude, position.coords.longitude)
                    ? {
                          altitude: position.coords.altitude,
                          coordenada: new firebase.firestore.GeoPoint(position.coords.latitude, position.coords.longitude),
                          datahora: new Date().getTime(),
                          latitude: position.coords.latitude,
                          longitude: position.coords.longitude,
                          precisao: position.coords.accuracy,
                          precisao_altitude: position.coords.altitudeAccuracy,
                          precisao_velocidade: 0,
                          provider: 'NAVIGATOR GEOLOCATION',
                          velocidade: position.coords.speed,
                          endereco: {},
                      }
                    : {
                          provider: 'EMPTY',
                          endereco: {},
                      },
            });

            this.ngxLogger.info(`\ndadosMarcacao.\nDados:`, dadosMarcacao);

            let compresssImageBase64: string | ArrayBuffer;
            if (webcamImage?.imageAsDataUrl) {
                const compressImage = (await this.ng2ImgMaxService
                    .compressImage(new File([this.imageService.dataURItoBlob(webcamImage.imageAsDataUrl)], uniqueId() + '.jpg', { type: 'image/jpeg' }), 0.2)
                    .pipe(take(1))
                    .toPromise()) as Blob;
                compresssImageBase64 = await this.imageService.blobToBase64(compressImage);
            }

            const foto = _.isNil(webcamImage?.imageAsDataUrl)
                ? null
                : {
                      conteudo: compresssImageBase64,
                      contentType: 'image/jpeg',
                      extensao: '.jpg',
                  };

            const callable_oncall_gravar_marcacao = this.fns.httpsCallable('oncall_gravar_marcacao');
            const data$ = callable_oncall_gravar_marcacao({
                contaId: userArray[0].conta_ref.id,
                marcacao: dadosMarcacao,
                tipo_app: 'flit-web',
                foto,
            });

            let marcacaoUid = '';
            LocalStorageLog.gravarArrayDeLogs<string>({
                buffer_length: 200,
                key_name: 'monitor_marcarpontoweb',
                data: `id: 2, message: "Usuário aguardou processamento base ser feito com navegador aberto.", data_proposta: "${formatDate(
                    datahoraOpen,
                    'dd/MM/yyyy HH:mm:ss',
                )}", next: 3`,
            });
            await data$
                .pipe(take(1))
                .toPromise()
                .then((response) => {
                    marcacaoUid = response.details.id_marcacao;
                    LocalStorageLog.gravarArrayDeLogs<string>({
                        buffer_length: 200,
                        key_name: 'monitor_marcarpontoweb',
                        data: `id: 3, message: "Marcação gravada no banco uid ${response}", data_proposta: "${formatDate(datahoraOpen, 'dd/MM/yyyy HH:mm:ss')}, next: 4`,
                    });
                    this._toastr.success('Marcação Gravada', '', {
                        positionClass: 'toast-top-center',
                    });
                })
                .catch((err) => {
                    this.ngxLogger.error('Erro ao chamar a função de gravar marcação.', { ...err });
                    loadingDialog.close();
                    LocalStorageLog.gravarArrayDeLogs<string>({
                        buffer_length: 200,
                        key_name: 'monitor_marcarpontoweb',
                        data: `id: 3, message: "Cloud fn retornou erro ao gravar marcação no banco de dados e mostrou notificação de erro.",data_proposta: "${formatDate(
                            datahoraOpen,
                            'dd/MM/yyyy HH:mm:ss',
                        )}, next: 4, err: ${err?.message ?? 'null'}`,
                    });
                    return this._toastr.error(`Tente novamente. ${err.details[0] ?? ''}`, 'Erro!', {
                        timeOut: 60000,
                        positionClass: 'toast-top-center',
                        progressBar: true,
                    });
                });
            if (marcacaoUid) {
                const marcacao = await this.afs
                    .doc(userArray[0].conta_ref.path + `/marcacoes/${marcacaoUid}`)
                    .get()
                    .pipe(
                        take(1),
                        map((marc) => {
                            return { uid: marc.id, ref: marc.ref, path: marc.ref.path, ...(marc.data() as {}) } as MarcacaoModel;
                        }),
                    )
                    .toPromise();

                loadingDialog
                    .afterClosed()
                    .pipe(take(1))
                    .subscribe(() => {
                        if (marcacao?.uid) {
                            this.comprovantePontoDialogService.abrirDialogComprovante(this.usuarioLogadoService.usuarioLogado$.getValue(), marcacao, 'primeira_abertura');
                            LocalStorageLog.gravarArrayDeLogs<string>({
                                buffer_length: 200,
                                key_name: 'monitor_marcarpontoweb',
                                data: `id: 4, message: "Comprovante aberto na tela", data_proposta: "${formatDate(datahoraOpen, 'dd/MM/yyyy HH:mm:ss')}, next: null`,
                            });
                        } else {
                            this._toastr.error('Tente novamente', 'Erro!', {
                                positionClass: 'toast-top-center',
                                progressBar: true,
                            });
                        }
                    });
            }
            loadingDialog.close();
        } catch (error) {
            LocalStorageLog.gravarArrayDeLogs<string>({ buffer_length: 100, data: error.message, key_name: 'e_marcarpontoweb' });
        }
    }

    /**
     * Retornar tipo de dispositivo.
     *
     * @returns {('Desktop' | 'Mobile' | 'Tablet' | 'Unknown')}
     * @memberof MarcarpontoService
     */
    retornarTipoDeDispositivo(): 'Desktop' | 'Mobile' | 'Tablet' | 'Unknown' {
        const isDesktopDevice = this.deviceService.isDesktop();
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        if (isDesktopDevice) {
            return 'Desktop';
        }
        if (isMobile) {
            return 'Mobile';
        }
        if (isTablet) {
            return 'Tablet';
        }
        return 'Unknown';
    }
}
