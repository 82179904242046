import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Timestamp, DocumentReference, DocumentSnapshot, DocumentData } from '@firebase/firestore-types';
import * as _ from 'lodash';

/**
 * Serviço destinado a realizar varias tarefas que podem ser comuns entre os módulos.
 *
 * @export
 * @class UtilitariosService
 */
@Injectable({
    providedIn: 'root',
})
export class UtilitariosService {
    /**
     * Cria uma instância de UtilitariosService.
     *
     * @param {NGXLogger} ngxLogger
     * @memberof UtilitariosService
     */
    constructor(private ngxLogger: NGXLogger) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos estáticos
    // -----------------------------------------------------------------------------------------------------
    static arrayDiasDaSemanaAbrev = new Array('DOM:', 'SEG:', 'TER:', 'QUA:', 'QUI:', 'SEX:', 'SAB:');
    static arrayDiasDaSemanaCompletos = new Array('Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado');
    static arrayDiasDaSemanaPadraoBanco = new Array('domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado');

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos públicos
    // -----------------------------------------------------------------------------------------------------

    /**
     * Remove os campos do objeto que tenham o valor === undefined incluindo os campos aninhados, depois retorna um novo objeto.
     *
     * @param {*} objeto
     * @returns {object}
     * @memberof UtilitariosService
     */
    removerCamposUndefined(objeto: any): object {
        return _.omitBy(objeto, _.isUndefined);
    }

    /**
     * Converte para number se for possível, senão retorna null.
     *
     * @param {*} valor
     * @returns {number}
     * @memberof UtilitariosService
     */
    converterParaNumber(valor: any): number {
        const numero = parseInt(valor, null) ?? null;
        return isNaN(numero) ? null : numero;
    }

    /**
     * Converte minutos que estejam em número para uma string com o formato do fuso, por exemplo 180 minutos ficará '+03:00'.
     * É utilizado onde é necessário exibir o fuso horário caso seja retornado pelo moment offSet, que retorna o fuso em quantidade de minutos como número.
     *
     * @param {*} n
     * @returns {string}
     * @memberof UtilitariosService
     */
    converterMinutosParaTimezone(minutosNumero: number): string {
        const sinal = minutosNumero < 0 ? '-' : '+';
        const horas = Math.floor(Math.abs(minutosNumero / 60));
        const minutos = minutosNumero % 60;
        const horasFormatadas = sinal + horas.toString().padStart(2, '0');
        const minutosFormatados = minutos.toString().padStart(2, '0');
        return horasFormatadas + ':' + minutosFormatados;
    }

    /**
     * Converte para number se for possível, senão retorna null.
     *
     * @param {*} n
     * @returns {number}
     * @memberof UtilitariosService
     */
    converterMinutosParaHHmm(n: number): string {
        if (!isNaN(n) && typeof n === 'number') {
            n = Math.abs(n);
            const maximoDeMinutosEmUmDia = 1440;
            const dias = Math.ceil(n / 60 / 24);
            if (dias < 1) {
                n = n - maximoDeMinutosEmUmDia * (dias - 1);
            }

            const hora: string = Math.floor(n / 60)
                .toString()
                .padStart(2, '0');
            const minutos: string = Math.ceil(n % 60)
                .toString()
                .padStart(2, '0');

            const HHmm = `${hora}:${minutos}`;
            if (HHmm === '24:00') {
                return '00:00';
            } else {
                return HHmm;
            }
        } else {
            return 'Indefinido';
        }
    }

    /**
     * Função que é um Helper publico para verificar se a latitude ou longitude são validas.
     *
     * @param {number} lat
     * @param {number} lng
     * @returns {boolean}
     * @memberof UtilitariosService
     */
    validarCoordenadasDePosicao(lat: number, lng: number): boolean {
        this.ngxLogger.info(`\nFunção validarCoordenadasDePosicao executada.\nParâmetros:`, { lat, lng });
        // Ajuste para caso seja passada uma string que pode ser convertida em numero.
        lat = Number(lat);
        lng = Number(lat);
        if (typeof lat === 'number' && typeof lng === 'number') {
            const latitudeValida: boolean = isFinite(lat) && Math.abs(lat) <= 90 && lat !== 0;
            const longitudeValida: boolean = isFinite(lng) && Math.abs(lng) <= 180 && lng !== 0;
            return latitudeValida && longitudeValida;
        } else {
            this.ngxLogger.error(`\nFunção validarCoordenadasDePosicao verificou que a latitude ou longitude são invalidas.\n`);
            return false;
        }
    }

    /**
     * Converte de Timestamp para Data ou null;
     *
     * @export
     * @param {firebase.firestore.Timestamp} timestamp
     * @returns {Date}
     */
    timestampParaData(timestamp: Timestamp): Date {
        if (tipoTimestampFirestore(timestamp)) {
            return timestamp.toDate();
        } else {
            return null;
        }
    }
}

/**
 * Typeguard para o tipo DocumentReference.
 *
 * @param {(DocumentReference | string)} ref
 * @returns {ref is DocumentReference}
 * @memberof Parametros
 */
export function tipoDocumentSnapshot(ref: DocumentSnapshot<DocumentData> | string): ref is DocumentSnapshot<DocumentData> {
    return (
        (ref as DocumentSnapshot<DocumentData>)?.ref !== undefined &&
        typeof (ref as DocumentSnapshot<DocumentData>)?.exists === 'boolean' &&
        (ref as DocumentSnapshot<DocumentData>)?.data() !== undefined
    );
}

/**
 * Typeguard para o tipo DocumentReference.
 *
 * @param {(DocumentReference | string)} ref
 * @returns {ref is DocumentReference}
 * @memberof Parametros
 */
export function tipoDocumentReference(ref: DocumentReference | string): ref is DocumentReference {
    return (ref as DocumentReference)?.path !== undefined;
}

/**
 * Typeguard para o tipo Timestamp do firestore.
 *
 * @export
 * @param {(Timestamp | string)} timestamp
 * @returns {timestamp is Timestamp}
 */
export function tipoTimestampFirestore(timestamp: Timestamp | string | number): timestamp is Timestamp {
    return (timestamp as Timestamp)?.seconds !== undefined && (timestamp as Timestamp)?.nanoseconds !== undefined;
}

/**
 * Typeguard para o tipo Date.
 *
 * @export
 * @param {*} date
 * @returns {date is Date}
 */
export function tipoDate(date: any): date is Date {
    return (date as Date)?.getTime() !== undefined && (date as Date)?.getDay() !== undefined;
}
