import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { GlobalService } from './core/services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
    const splashScreen: HTMLElement = document.getElementById('website-loading');
    if (splashScreen) {
      splashScreen.remove();
    }
    this.authService.initAuthListener();
    this.globalService.setAccountData();
    this.globalService.gerarDispositivoUid();
  }
}
