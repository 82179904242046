<div fxLayout="column" id="forgot-password">
    <div id="forgot-password-form-wrapper" fxFlex>
        <div id="forgot-password-form" style="height: 100%;" fxLayoutAlign="center center">
            <mat-card class="forgot-password-card mat-elevation-z8" [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">
                <div class="logo" fxLayout="row">
                    <img src="assets/images/logos/logo_assinatura_azul.svg" />
                </div>
                <div class="title">Recuperação de Senha.</div>
                <h4 class="secondary-text">Informe o seu e-mail para continuar.</h4>
                <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>
                <mat-card-content fxLayout="column">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" />
                    <mat-icon matSuffix class="secondary-text">mail_outline</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        Email Obrigatorio.
                    </mat-error>
                    <mat-error
                    *ngIf="
                            !forgotPasswordForm.get('email').hasError('required') &&
                            forgotPasswordForm.get('email').hasError('pattern')
                        ">
                        Email inválido.
                    </mat-error>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" class="submit-button" aria-label="LOGIN" [disabled]="forgotPasswordForm.invalid"
                (click)="authService.sendPasswordResetEmail(forgotPasswordForm.get('email').value)">
                Enviar <mat-icon *ngIf="forgotPasswordForm.valid">check</mat-icon>
            </button>
            <div class="return-login" fxLayout="column" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/metodo-login'">
                    Voltar ao Login.
                </a>
            </div>
        </mat-card-actions>
    </form>
        </mat-card>
        </div>
    </div>
</div>