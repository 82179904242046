import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './auth/auth-guard.service';
import { ForgotpasswordComponent } from './auth/forgot-password/forgotpassword.component';
import { MetodoLoginComponent } from './auth/metodo-login/metodo-login.component';
import { MarcarpontoService } from './marcarponto/marcarponto.service';

const routes: Routes = [
    // LOGIN ROUTES
    { path: 'auth/metodo-login', component: MetodoLoginComponent },
    /*   { path: 'auth/login', component: LoginComponent }, */
    { path: 'auth/redefinirSenha', component: ForgotpasswordComponent },
    /* { path: '**', component: MainComponent, canActivate: [AuthGuardService] } */

    // USUARIO ROUTES
    {
        path: 'main',
        loadChildren: () => import('./usuario/main.module').then((m) => m.MainModule),
        canLoad: [AuthGuardService],
    },

    // EMPRESA ROUTES
    {
        path: 'empresa',
        loadChildren: () => import('./empresa/empresa.module').then((m) => m.EmpresaModule),
        canLoad: [AuthGuardService],
    },

    // REDIRECT ROUTES
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: localStorage.getItem('metodo-login') === 'colaborador' ? 'main' : localStorage.getItem('metodo-login') === 'empresa' ? 'empresa' : 'auth/metodo-login',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [AuthGuardService, MarcarpontoService],
})
export class AppRoutingModule {}
