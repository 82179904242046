import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { MarcacaoModel } from '@shared/models/marcacao.model';
import { Usuario } from '@shared/models/usuario.model';
import { UtilitariosService } from '@shared/services/utilitarios.service';
import { saveAs } from 'file-saver';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

import { ComprovantePontoDialogService } from './comprovante-ponto-dialog.service';
import { LocalStorageLog } from '@shared/helpers/local-storage-log';
import { DateTimeHelper } from '@shared/helpers/dataTime-helper';

/**
 * Componente do comprovante de ponto.
 *
 * @export
 * @class ComprovantePontoDialogComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'comprovante-ponto-dialog',
    templateUrl: './comprovante-ponto-dialog.component.html',
    styleUrls: ['./comprovante-ponto-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ComprovantePontoDialogComponent implements OnInit, OnDestroy {
    // <-- Propriedades privadas
    // <-- Pegando comprovante no HTML
    private comprovante: ElementRef;
    @ViewChild('comprovante', { static: false }) set content(content: ElementRef) {
        this.comprovante = content;
    }
    // Pegando comprovante no HTML -->
    private protecaoClickSalvar = false;
    // Propriedades privadas -->

    // <-- Propriedades públicas
    public token$: BehaviorSubject<{ token: string; erro?: string }> = new BehaviorSubject({ token: '', erro: '' });
    // Propriedades públicas -->

    // <-- Helpers
    public dateTimeHelper = DateTimeHelper;
    // Helpers -->

    constructor(
        public comprovantePontoDialogRef: MatDialogRef<ComprovantePontoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dadosPassadosDialog: { usuario: Partial<Usuario>; marcacao: MarcacaoModel; modo: 'primeira_abertura' },
        private ngxLogger: NGXLogger,
        private comprovantePontoDialogService: ComprovantePontoDialogService,
        private utilitariosService: UtilitariosService,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Ganchos do ciclo de vida
    // -----------------------------------------------------------------------------------------------------
    /**
     * ngOnInit
     *
     * @memberof ComprovantePontoDialogComponent
     */
    ngOnInit(): void {
        this.comprovantePontoDialogService
            .buscarToken({
                appName: this.dadosPassadosDialog.marcacao?.appinfo?.name,
                appVersion: this.dadosPassadosDialog.marcacao?.appinfo?.version,
                datahora: this.dadosPassadosDialog.marcacao.datahora.seconds,
                id: this.dadosPassadosDialog.marcacao.uid,
                userId: this.dadosPassadosDialog.marcacao.usuario_ref.id,
            })
            .then((resultado) => {
                if (this.dadosPassadosDialog.modo === 'primeira_abertura') {
                    LocalStorageLog.gravarArrayDeLogs({ buffer_length: 100, key_name: 'tokens_comprovantes', data: resultado });
                }
                this.token$.next(resultado);
            });
    }

    /**
     * ngOnDestroy
     *
     * @memberof ComprovantePontoDialogComponent
     */
    ngOnDestroy(): void {
        this.ngxLogger.info(`\nFunção ngOnDestroy do componente ComprovantePontoDialogComponent executada.`);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos privados
    // -----------------------------------------------------------------------------------------------------
    /**
     * Função para pegar o nome do colaborador e e retornar para
     * a estrutura de nome de arquivo.
     * Até 10 caracteres.
     * Lower Case.
     * Sem espaço ou caracteres especiais.
     *
     * @private
     * @param {string} nome
     * @returns {string}
     * @memberof ComprovantePontoDialogComponent
     */
    private retornarNomeColaboradorParaArquivo(nome: string): string {
        const separador = '-';
        if (typeof nome === 'string') {
            const nome_cortado = nome.slice(0, 10);
            const nome_ajuste = nome_cortado.replace(/[^A-Z0-9]/gi, '').toLowerCase();
            return nome_ajuste ? nome_ajuste + separador : 'nome_curto' + separador;
        } else {
            return 'nome_curto' + separador;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos públicos
    // -----------------------------------------------------------------------------------------------------

    /**
     * Função para salvar o comprovante como imagem.
     *
     * @memberof ComprovantePontoDialogComponent
     */
    public salvarComprovanteClick(): void {
        this.ngxLogger.info(`\nFunção salvarComprovanteClick executada.`);
        // Proteção para forçar o usuário aguardar a geração do Token, e se clicar em salvar,
        // aguardar a Promisse da blob.
        if (this.token$.getValue().token && this.protecaoClickSalvar === false) {
            this.protecaoClickSalvar = true;
            const data: Date = this.utilitariosService.timestampParaData(this.dadosPassadosDialog.marcacao?.datahora);

            // Criando imagem com base no conteúdo para salvar.
            html2canvas
                .default(this.comprovante.nativeElement, { backgroundColor: '#FFFFFF' })
                .then((canvas) => {
                    canvas.toBlob(
                        (blob) => {
                            saveAs(blob, `${this.retornarNomeColaboradorParaArquivo(this.dadosPassadosDialog.usuario?.nome)}${moment(data).format(`DD-MM-YYYY-hh-mm`)}.png`);
                            this.comprovantePontoDialogRef.close();
                            this.protecaoClickSalvar = false;
                        },
                        'image/png',
                        1,
                    );
                })
                .catch((erro) => {
                    this.ngxLogger.error(`\nErro na promise da função salvarComprovanteClick.\n`, erro);
                    this.protecaoClickSalvar = false;
                });
        }
    }
}
