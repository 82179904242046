import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ValidateBrService } from 'angular-validate-br';
import { GlobalService } from 'app/core/services/global.service';
import { ValidationPatternAndMaskService } from 'app/shared/services/validation-pattern-and-mask.service';
import firebase from 'firebase/app';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, noop, Observable, Subject } from 'rxjs';
import { delay, take, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Component({
    selector: 'app-metodo-login',
    templateUrl: './metodo-login.component.html',
    styleUrls: ['./metodo-login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class MetodoLoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    loginEmpresaForm: FormGroup;

    // EXTRA FEATURES FORM
    private ngUnsubscribe$ = new Subject<void>();

    loading = false;
    userLogged$: Observable<boolean>;

    metodoLogin: 'colaborador' | 'empresa';

    documentoMask: BehaviorSubject<string> = new BehaviorSubject(this._validationHelper.companyCpfMask);

    @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;
    @ViewChild('documentoInput') documentoInput: ElementRef<HTMLInputElement>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _formBuilder: FormBuilder,
        private authService: AuthService,
        private globalService: GlobalService,
        private snackBar: MatSnackBar,
        private router: Router,
        public _validationHelper: ValidationPatternAndMaskService,
        private _validateBrService: ValidateBrService,
        private ngZone: NgZone,
        private ngxLogger: NGXLogger,
    ) {
        this.metodoLogin = 'colaborador';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ life cycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Redirect if user loggedIn
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });

        this.loginEmpresaForm = this._formBuilder.group({
            documento: ['', [Validators.required, Validators.email]],
            pin: ['', Validators.required],
        });

        /**
         * @DOCUMENTO_INIT
         * @param documentoControl
         * @param documentoMask
         * @param {ValidationPatternAndMaskService} _validationHelper
         * @param {ValidateBrService} _validateBrService
         * @param {Validators}
         **/
        this.documentoControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe$), delay(100)).subscribe((value) => {
            this.documentoControl.updateValueAndValidity();
            if ((value || '').length <= 14) {
                this.documentoControl.setValidators([this._validateBrService.cpf, Validators.required]);
                this.documentoMask.next(this._validationHelper.companyCpfMask);
                // I've put an extra value here in order to have the same number of characters from the second-mask.
            } else {
                this.documentoControl.setValidators([this._validateBrService.cnpj, Validators.required]);
                this.documentoMask.next(this._validationHelper.cnpjMask);
            }
        });
        /**
         * @DOCUMENTO_END
         **/
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private loginOkNotification(): void {
        this.snackBar.open('Logado com sucesso, bem vindo!', 'OK', {
            duration: 3000,
        });
    }

    private loginErrorNotification(): void {
        this.snackBar.open('Credenciais inválidas ou usuário não cadastrado | Em caso de dúvida, entre em contato com o nosso suporte.', 'OK', { duration: 5000 });
    }

    clear(): void {
        this.loginForm.controls['password'].setValue('');
        this.pinControl.setValue(null);
    }

    setMetodoLogin(metodo: 'colaborador' | 'empresa'): void {
        this.metodoLogin = metodo;
    }

    async loginEmailESenha(): Promise<void> {
        localStorage.setItem('metodo-login', 'colaborador');
        this.loading = true;
        const formValue: any = this.loginForm.value;
        this.authService
            .loginEmailESenha(formValue.email, formValue.password)
            .pipe(
                take(1),
                tap(async (credential: firebase.auth.UserCredential) => {
                    const TOKEN_RESULT = await credential.user.getIdTokenResult(true);
                    /* this.ngxLogger.info(`\nFunção  this.authService.loginEmailESenha executada.\nDados:`, {
                        _interno: {
                            TOKEN_RESULT,
                        },
                    }); */
                    const loginPermitido = await this.globalService.verificarPermissoes(credential.user.uid);
                    if (!loginPermitido) {
                        this.authService.logout();
                        this.loginErrorNotification();
                        this.loading = false;
                        this.clear();
                        this.emailInput.nativeElement.focus();
                    } else {
                        this.loading = false;
                    }
                }),
            )
            .subscribe(noop, (err) => {
                /* console.log(err); */
                this.loginErrorNotification();
                this.loading = false;
                this.clear();
                this.emailInput.nativeElement.focus();
                /* HelperAuthError(err, this.toastr); */
            });
    }

    loginCustomToken(): void {
        localStorage.setItem('metodo-login', 'empresa');
        this.loading = true;
        const formValue: any = this.loginForm.value;
        this.authService.buscarCustomTokenLogin(this.documentoControl.value, this.pinControl.value).subscribe(
            (data) => {
                this.authService
                    .loginCustomToken(data.details.custom_token)
                    .pipe(
                        take(1),
                        tap(async (credential: firebase.auth.UserCredential) => {
                            /* console.log(credential.user.refreshToken); */
                            /*  const TOKEN_RESULT = await credential.user.getIdTokenResult(
                  true
                );
                console.log(TOKEN_RESULT); */
                            /* this.loginOkNotification(); */
                            this.ngZone.run(() => {
                                this.router.navigate(['/empresa']);
                            });
                            this.loading = false;
                        }),
                    )
                    .subscribe(noop, (err) => {
                        console.log(err);
                        this.loginErrorNotification();
                        this.loading = false;
                        this.clear();
                        this.documentoInput.nativeElement.focus();
                        /* HelperAuthError(err, this.toastr); */
                    });
            },
            (err) => {
                /*  console.log(err); */
                this.loginErrorNotification();
                this.loading = false;
                this.clear();
                this.documentoInput.nativeElement.focus();
            },
        );
    }

    get documentoControl(): AbstractControl {
        return this.loginEmpresaForm ? this.loginEmpresaForm.get('documento') : null;
    }

    get pinControl(): AbstractControl {
        return this.loginEmpresaForm ? this.loginEmpresaForm.get('pin') : null;
    }
}
