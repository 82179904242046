<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
    <div id="login-intro" fxFlex gdAreas="header header | content content" gdGap="16px" gdRows="auto auto auto" fxHide
        fxShow.gt-xs fusePerfectScrollbar>
        <!-- <div gdArea="header" class='lang-select' [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            <span>{{'INTRO_AUTH.LANGUAGES.TITLE'}}:</span>
            <button mat-button class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.display}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{"INTRO_AUTH.LANGUAGES"}}</span>
                    </span>
                </button>

            </mat-menu>
        </div> -->

        <div class="welcome-wrapper" gdArea="content" fxLayoutAlign="center center" fxLayout="column">
            <div fxLayout="column" fxLayoutAlign="center center">
                <div class="title" [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">
                    {{ 'Bem vindo(a) ao' }}
                </div>
                <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
                    <img src="assets/images/logos/logo_assinatura_branca.svg" />
                </div>

                <div class="description" [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }">
                    {{ 'ESCOLHA SEU MÉTODO DE LOGIN:' }}
                </div>
                <div>
                    <button [ngClass]="{'disabled': metodoLogin === 'colaborador'}" class="m-12 select-colaborador-btn"
                        mat-raised-button (click)="setMetodoLogin('colaborador')">Flit - Individual <mat-icon>account_circle
                        </mat-icon></button>
                    <button [ngClass]="{'disabled': metodoLogin === 'empresa'}" class="m-12 select-empresa-btn" mat-raised-button
                        (click)="setMetodoLogin('empresa')">Flit Multi - Empresa <mat-icon>business</mat-icon></button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="metodoLogin === 'colaborador'; else loginEmpresa" id="login-form-wrapper" fusePerfectScrollbar
        [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
        <div id="login-form">
            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/logo_assinatura_azul.svg" />
            </div>
                    <div  fxHide.gt-xs class="mb-20">
                        <button [ngClass]="{'disabled': metodoLogin === 'colaborador'}" class="m-12 select-colaborador-btn"
                            mat-raised-button (click)="setMetodoLogin('colaborador')">Flit - Individual <mat-icon>account_circle
                            </mat-icon></button>
                        <button [ngClass]="{'disabled': metodoLogin === 'empresa'}" class="m-12 select-empresa-btn" mat-raised-button
                            (click)="setMetodoLogin('empresa')"> <div></div>Flit Multi - Empresa <mat-icon>business</mat-icon></button>
                    </div>

            <div class="title">{{ 'LOGIN' }}</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input #emailInput matInput formControlName="email" autocomplete="new-password" />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        {{ 'E-mail é obrigatório' }}
                    </mat-error>
                    <mat-error *ngIf="
              !loginForm.get('email').hasError('required') &&
              loginForm.get('email').hasError('email')
            ">
                        {{ 'Por favor insira um endereço de e-mail válido' }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Senha' }}</mat-label>
                    <input matInput type="password" formControlName="password" autocomplete="pin" />
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        {{ 'Senha requerida' }}
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end">
                    <!-- <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Remember Me
                    </mat-checkbox> -->

                    <a class="forgot-password" [routerLink]="'/auth/redefinirSenha'">
                        {{ 'Esqueceu a senha?' }}
                    </a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN"
                    [disabled]="loginForm.invalid || loading || (userLogged$ | async)" (click)="loginEmailESenha()">
                    {{ 'LOGIN' }}
                </button>
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </form>

            <!-- <div class="separator">
                <span class="text">OR</span>
            </div>

            <div fxLayout="column" fxLayoutAlign="start center">

                <button mat-raised-button class="google">
                    Log in with Google
                </button>

                <button mat-raised-button class="facebook">
                    Log in with Facebook
                </button>

            </div> -->

            <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/pages/auth/register-2'">Create an account</a>
            </div> -->
        </div>
    </div>
    <ng-template #loginEmpresa>
        <div id="login-form-wrapper" fusePerfectScrollbar
            [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
            <div id="login-form">
                <div class="logo" fxHide.gt-xs>
                    <img src="assets/images/logos/logo_assinatura_azul.svg" />
                </div>

                <div  fxHide.gt-xs class="mb-20">
                    <button [ngClass]="{'disabled': metodoLogin === 'colaborador'}" class="disabled m-12"
                        mat-raised-button (click)="setMetodoLogin('colaborador')">Colaborador <mat-icon>account_circle
                        </mat-icon></button>
                    <button [ngClass]="{'disabled': metodoLogin === 'empresa'}" class="m-12" mat-raised-button
                        (click)="setMetodoLogin('empresa')">Empresa <mat-icon>business</mat-icon></button>
                </div>

                <div class="title">{{ 'EMPRESA LOGIN' }}</div>

                <form name="loginEmpresaForm" [formGroup]="loginEmpresaForm" novalidate>
                    <mat-form-field appearance="outline">
                        <mat-label>CPF / CNPJ </mat-label>
                        <input #documentoInput mask="{{ documentoMask | async }}" matInput formControlName="documento" autocomplete="new-password" />
                        <mat-icon matSuffix class="secondary-text">business</mat-icon>
                        <mat-error *ngIf="documentoControl.hasError('required')">
                            {{ 'Documento é obrigatório' }}
                        </mat-error>
                        <mat-error
                            *ngIf="documentoControl.hasError('cpfvalidator') && !documentoControl.hasError('cnpjvalidator')">
                            {{"CPF inválido."}}
                        </mat-error>
                        <mat-error
                            *ngIf="!documentoControl.hasError('cpfvalidator') && documentoControl.hasError('cnpjvalidator')">
                            {{"CNPJ inválido."}}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'PIN' }}</mat-label>
                        <input matInput type="password" formControlName="pin" autocomplete="pin"/>
                        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                        <mat-error>
                            {{ 'PIN requerido' }}
                        </mat-error>
                    </mat-form-field>

                    <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN"
                        [disabled]="loginEmpresaForm.invalid || loading || (userLogged$ | async)" (click)="loginCustomToken()">
                        {{ 'LOGIN' }}
                </button>
                    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                </form>

                <!-- <div class="separator">
                <span class="text">OR</span>
            </div>

            <div fxLayout="column" fxLayoutAlign="start center">

                <button mat-raised-button class="google">
                    Log in with Google
                </button>

                <button mat-raised-button class="facebook">
                    Log in with Facebook
                </button>

            </div> -->

                <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/pages/auth/register-2'">Create an account</a>
            </div> -->
            </div>
        </div>
    </ng-template>
</div>