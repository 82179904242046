import { NgxLoggerLevel } from 'ngx-logger';
import * as pjson from '../../package.json';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    hmr: false,
    production: false,
    logLevel: NgxLoggerLevel.TRACE,
    serverLogLevel: NgxLoggerLevel.OFF,
    firebaseConfig: {
        // DEV
        apiKey: 'AIzaSyD9zwhbZWmkdvfFbZZHhoQEdrM-yvIAQGM',
        authDomain: 'flittest-49e49.firebaseapp.com',
        databaseURL: 'https://flittest-49e49-status-terminal.firebaseio.com/',
        projectId: 'flittest-49e49',
        storageBucket: 'flittest-49e49.appspot.com',
        messagingSenderId: '985904738863',
        appId: '1:985904738863:web:7a8fd71902f8da29',

        // PROD
        /* apiKey: "AIzaSyBBAxIFkQnIUj8CLWHFE76B6pbK8rh2MJ8",
    authDomain: "project-flit.firebaseapp.com",
    databaseURL: "https://project-flit.firebaseio.com",
    projectId: "project-flit",
    storageBucket: "project-flit.appspot.com",
    messagingSenderId: "295634779569",
    appId: "1:295634779569:web:b1b8677b681aa11f" */
    },
    agmConf: {
        apiKey: 'AIzaSyBfFas1QzF-eMntrpLNqaQ9O3mEBHkh_R4',
        language: 'pt-BR',
        provider: 'google',
        httpAdapter: 'https',
        region: 'BR',
    },
    appName: 'Flit Web',
    empresaAppName: 'Flit Web Multi',
    version: `${pjson.version}_dev`,
    buildversion: 411,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
