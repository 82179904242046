import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { RouteConfigLoadStart, Router } from '@angular/router';
import firebase from 'firebase/app';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { User } from '../models/user.model';
import { DeviceDetectionService } from '../shared/services/device-detection.service';
import { RequestEmpresaAuthModel } from './models/request-empresa-auth';

@Injectable()
export class AuthService {
    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private toastr: ToastrService,
        private afs: AngularFirestore,
        private fns: AngularFireFunctions,
        private _deviceDetectionService: DeviceDetectionService,
        private ngZone: NgZone,
        private ngxLogger: NGXLogger,
    ) {}
    loginEmailESenha(email: string, password: string): Observable<firebase.auth.UserCredential> {
        return from(this.afAuth.signInWithEmailAndPassword(email, password));
    }

    buscarCustomTokenLogin(empresa_documento: string, pin: string): Observable<any> {
        const callable = this.fns.httpsCallable<RequestEmpresaAuthModel>('autenticar_terminal');

        return callable({
            empresa_documento,
            pin,
            dispositivo: {
                app_info: { ...this._deviceDetectionService.retornarAppInfo() },
                ...this._deviceDetectionService.retornarDadosDispositivo(),
                device_id: localStorage.getItem('di') || 'errGetLS(di)',
            },
        }).pipe(take(1));
    }

    loginCustomToken(customToken: string): Observable<firebase.auth.UserCredential> {
        return from(this.afAuth.signInWithCustomToken(customToken));
    }

    async logout(): Promise<void> {
        await this.afAuth.signOut();
        /* console.log('fn log') */
        // window.location.reload(); RESOLVE O PROBLEMA DO FLUXO DE UMA FORMA DESELEGANTE.
    }

    /*   isLoggedIn(): boolean {
      const user = JSON.parse(localStorage.getItem('user'));
      return user !== null;
    } */

    isLoggedIn(): Observable<boolean> {
        return this.afAuth.authState.pipe(
            map((u) => {
                const metodoLogin = localStorage.getItem('metodo-login');
                if (metodoLogin === 'colaborador' || metodoLogin === 'empresa') {
                    return u ? true : false;
                } else {
                    return false;
                }
            }),
        );
    }

    getUser(): Observable<User> {
        return this.afAuth.authState.pipe(
            switchMap((user) => {
                if (user) {
                    return this.afs
                        .doc<User>(`usuarios/${user.uid}`)
                        .snapshotChanges()
                        .pipe(
                            map((docSnap) => {
                                const uid = docSnap.payload.id;
                                const ref = docSnap.payload.ref;
                                const data = docSnap.payload.data();
                                return new User({ uid, ref, ...data });
                            }),
                        );
                } else {
                    return of(null);
                }
            }),
        );
    }

    initAuthListener(): void {
        this.ngxLogger.info(`\nFunção initAuthListener executada.\nParâmetros:`, {});
        this.afAuth.authState.subscribe((user) => {
            if (user) {
                const metodoLogin = localStorage.getItem('metodo-login');
                if (metodoLogin) {
                    switch (metodoLogin) {
                        case 'colaborador':
                            if (this.router.url === '/auth/metodo-login') {
                                this.router.navigate(['/main']);
                            } else {
                                this.ngZone.run(() => {
                                    this.router.events.pipe(take(1)).subscribe((res) => {
                                        this.ngxLogger.debug(`\nthis.router.events authListener emitiu valor.\nParâmetros:`, { res });
                                        if (res instanceof RouteConfigLoadStart) {
                                            if (res.route.path !== 'main') {
                                                this.router.navigate(['/main']);
                                            }
                                        }
                                    });
                                });
                            }

                            break;

                        case 'empresa':
                            this.ngZone.run(() => {
                                if (this.router.url === '/auth/metodo-login') {
                                    this.router.navigate(['/empresa']);
                                } else {
                                    this.router.events.pipe(take(1)).subscribe((res) => {
                                        this.ngxLogger.debug(`\nthis.router.events authListener emitiu valor.\nParâmetros:`, { res });
                                        if (res instanceof RouteConfigLoadStart) {
                                            if (res.route.path !== 'empresa') {
                                                this.router.navigate(['/empresa']);
                                            }
                                        }
                                    });
                                }
                            });

                            break;

                        default:
                            this.afAuth.signOut();
                            break;
                    }
                } else {
                    this.afAuth.signOut();
                }
            } else {
                localStorage.removeItem('metodo-login');
                // Se acontecer algo com a autenticação e o usuário estiver logado ele des-loga.
                this.router.navigate(['/auth/metodo-login']);
            }
        });
    }

    sendPasswordResetEmail(emailAddress): void {
        this.afAuth
            .sendPasswordResetEmail(emailAddress)
            .then(() => {
                this.toastr.success('Verifique seu e-mail !', '', {
                    positionClass: 'toast-top-center',
                });
                this.router.navigate(['/auth/metodo-login']);
                // Email sent.
            })
            .catch((error) => {
                this.toastr.error('Tente novamente mais tarde ou entre em contato com nosso suporte!', '', {
                    positionClass: 'toast-top-center',
                });
                this.router.navigate(['/auth/metodo-login']);
                // console.log(error.message);
            });
    }
}
