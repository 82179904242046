<div fxLayout="column" id="login">
  <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
  <div id="login-form">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-card class="login-card mat-elevation-z8">
        <div class="logo" fxLayout="row">
          <img src="assets/images/logos/flit_logo_color_w128.png" />
        </div>
        <div class="title" fxLayoutAlign="center center" class="matIten">Seja bem vindo!</div>
        <mat-card-content fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label>E-mail</mat-label>
            <input #emailInput matInput formControlName="email" type="email" autocomplete="email" readonly="false"
              autofocus />
            <mat-icon matSuffix class="secondary-text">mail_outline</mat-icon>
            <mat-error *ngIf="loginForm.get('email').hasError('required')">
              Email Obrigatorio.
            </mat-error>
            <mat-error *ngIf="
                !loginForm.get('email').hasError('required') &&
                loginForm.get('email').hasError('email')
              ">
              Email Invalido.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Senha</mat-label>
            <input matInput formControlName="password" type="password" />
            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
            <mat-error>
                Senha obrigatoria.
            </mat-error>
          </mat-form-field>
          <a fxLayoutAlign="end" class="forgot-password" [routerLink]="'/auth/redefinirSenha'">
            Esqueceu a senha?
        </a>
        </mat-card-content>
        <mat-card-actions>
          <div fxLayout="row" fxLayoutAlign="center center">
            <button class="matIten"mat-raised-button
            color="primary"
            class="submit-button" type="submit" [disabled]="!loginForm.valid || loading">
              Entrar <mat-icon *ngIf="loginForm.valid">check</mat-icon>
            </button>
          </div>
        </mat-card-actions>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </mat-card>
    </form>
    </div>
  </div>
</div>