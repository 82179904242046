import { format as formatDate } from 'date-fns';

export interface ILocalStorageLog<T> {
    data: T;
    key_name: string;
    buffer_length: number;
}

export abstract class LocalStorageLog {
    static gravarArrayDeLogs<T>(params: ILocalStorageLog<T>): void {
        try {
            const erroLog = JSON.parse(localStorage.getItem(params.key_name) ?? '[]') as { date: string; data: T }[];
            if (erroLog.length > params.buffer_length) {
                erroLog.splice(0, erroLog.length - params.buffer_length);
            }
            erroLog.push({ date: formatDate(new Date(), 'dd/MM/yyyy HH:mm:ss'), data: params.data ?? ('No data' as any) });
            localStorage.setItem(params.key_name, JSON.stringify(erroLog));
        } catch (error) {
            console.warn('HABILITE OU USE UM NAVEGADOR COM LOCAL STORAGE PARA OBTER O SUPORTE COM LOGS');
        }
    }
}
