import { DocumentReference } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';
import * as _ from 'lodash';

export class User {
  ref?: DocumentReference;
  uid?: string;

  aceite_termo?: Timestamp;
  admissao?: Timestamp;
  ativo?: boolean;
  avatar_thumbnail_path?: string;
  avatar_thumbnail_url?: string;
  avatarUrl?: string;
  cargo_ref?: DocumentReference;
  celular?: string;
  conta_ref?: DocumentReference;
  cpf?: string;
  departamento_ref?: DocumentReference;
  desligamento?: Timestamp;
  email?: string;
  empresa_ref?: DocumentReference;
  endereco?: {
    bairro?: string;
    cep?: string;
    cidade?: string;
    complemento?: string;
    logradouro?: string;
    numero?: string;
    uf?: string;
  };
  escala_ref?: DocumentReference;
  excluido?: boolean;
  interno?: boolean;
  nascimento?: Timestamp;
  nome?: string;
  nomeCompleto?: string;
  observacoes?: string;
  parametros?: {
    acessar_manager: boolean;
    controlar_ponto: boolean;
    marca_ponto_flit: boolean;
    marca_ponto_web: boolean;
    marca_ponto_multi_empresa: boolean;
    reconhecimento_facial: boolean;
    reconhecimento_facial_obrigatorio: boolean;
  };
  perimetro_ref: DocumentReference;
  pin?: number;
  pis?: string;
  status?: string;
  telefone?: string;
  token_notificacao?: { android: string };

  constructor(payload?: Partial<User> | any) {
    {
      const data = payload || {};

      this.ref = data.ref || null;
      this.uid = data.uid || null;

      this.aceite_termo = data.aceite_termo || null;
      this.admissao = data.admissao || null;
      this.ativo = data.ativo || true;
      this.avatar_thumbnail_path = data.avatar_thumbnail_path || '';
      this.avatar_thumbnail_url = data.avatar_thumbnail_url || '';
      this.avatarUrl = data.avatarUrl || '';
      this.cargo_ref = data.cargo_ref || null;
      this.celular = data.celular || '';
      this.conta_ref = data.conta_ref || null;
      this.cpf = data.cpf || '';
      this.departamento_ref = data.departamento_ref || null;
      this.desligamento = data.desligamento || null;
      this.email = data.email || '';
      this.empresa_ref = data.empresa_ref || null;
      this.endereco = {
        cep: (data.endereco || {}).cep || '',
        cidade: (data.endereco || {}).cidade || '',
        uf: (data.endereco || {}).uf || '',
        bairro: (data.endereco || {}).bairro || '',
        logradouro: (data.endereco || {}).logradouro || '',
        numero: (data.endereco || {}).numero || '',
        complemento: (data.endereco || {}).complemento || ''
      };
      this.escala_ref = data.escala_ref || null;
      this.excluido = data.excluido || false;
      this.interno = data.interno || true;
      this.nascimento = data.nascimento || null;
      this.nome = data.nome || '';
      this.nomeCompleto = data.nomeCompleto || '';
      this.observacoes = data.observacoes || '';
      this.parametros = {
        acessar_manager: _.isNil(
          (data.parametros || {}).acessar_manager
        )
          ? false
          : (data.parametros || {}).acessar_manager,
        controlar_ponto: _.isNil(
          (data.parametros || {}).controlar_ponto
        )
          ? false
          : (data.parametros || {}).controlar_ponto,
        marca_ponto_flit: _.isNil(
          (data.parametros || {}).marca_ponto_flit
        )
          ? false
          : (data.parametros || {}).marca_ponto_flit,
        marca_ponto_web: _.isNil(
          (data.parametros || {}).marca_ponto_web
        )
          ? false
          : (data.parametros || {}).marca_ponto_web,
        marca_ponto_multi_empresa: _.isNil(
          (data.parametros || {}).marca_ponto_multi_empresa
        )
          ? false
          : (data.parametros || {}).marca_ponto_multi_empresa,
        reconhecimento_facial: _.isNil(
          (data.parametros || {}).reconhecimento_facial
        )
          ? false
          : (data.parametros || {}).reconhecimento_facial,
        reconhecimento_facial_obrigatorio: _.isNil(
          (data.parametros || {}).reconhecimento_facial_obrigatorio
        )
          ? false
          : (data.parametros || {}).reconhecimento_facial_obrigatorio
      };
      this.perimetro_ref = data.perimetro_ref || null;
      this.pin = data.pin || 0;
      this.pis = data.pis || '';
      this.status = data.status || 'Ativo';
      this.telefone = data.telefone || '';
      this.token_notificacao = {
        android: (data.token_notificacao || {}).android || ''
      };
    }
  }
}
