import { EntradaESaida } from '@shared/models/entrada-saida.model';
import { MarcacaoModel } from '@shared/models/marcacao.model';

export interface JornadaAnteriorRefInfo {
    data_fim: Date;
    diff: number;
    index_ref: number;
    ultima_marcacao: MarcacaoModel;
}

export class TrabalhoDoDiaApurado {
    jornadaAnteriorRefInfo?: JornadaAnteriorRefInfo;
    marcacoesDoDiaAjustadas: EntradaESaida<MarcacaoModel>[];
    tempoIntervaloDoDiaArray: number[];
    tempoIntervaloDoDiaEmMinutos: number; // Começa com menos 2 para saber que ainda não foi usado.
    tempoTrabalhadoNoDiaEmMinutos: number; // Começa com menos 2 para saber que ainda não foi usado.

    constructor({
        jornadaAnteriorRefInfo,
        marcacoesDoDiaAjustadas,
        tempoIntervaloDoDiaArray,
        tempoIntervaloDoDiaEmMinutos,
        tempoTrabalhadoNoDiaEmMinutos,
    }: Partial<TrabalhoDoDiaApurado> = {}) {
        this.jornadaAnteriorRefInfo = jornadaAnteriorRefInfo ?? { data_fim: undefined, diff: undefined, index_ref: undefined, ultima_marcacao: undefined };
        this.marcacoesDoDiaAjustadas = marcacoesDoDiaAjustadas ?? [];
        this.tempoIntervaloDoDiaArray = tempoIntervaloDoDiaArray ?? [];
        this.tempoIntervaloDoDiaEmMinutos = tempoIntervaloDoDiaEmMinutos ?? -2;
        this.tempoTrabalhadoNoDiaEmMinutos = tempoTrabalhadoNoDiaEmMinutos ?? -2;
    }
}
